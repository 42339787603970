import React, { useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonPopover,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { Redirect, Route, useHistory, useLocation } from "react-router";
import Profile from "./pages/profile/Profile";
import Evidence from "./pages/evidence/Evidence";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

import "./App.css";
import { add } from "ionicons/icons";

import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { evidenceAtom, userAtom, userProgramsAtom } from "./state/State";
import _ from "lodash";
import EvidenceModal from "./components/common/EvidenceModal";
import EditRole from "./pages/profile/EditRole";
import {
  TabBar_Dash_Active,
  TabBar_Dash_Inactive,
  Tab_Evidence_Active,
  Tab_Evidence_Inactive,
  Tab_Guidelines_Active,
  Tab_Guidelines_Inactive,
  Tab_Profile_Active,
  Tab_Profile_Inactive,
  Tab_Timeline_Active,
  Tab_Timeline_Inactive,
} from "./assets/images";
import { EventRegister } from "react-native-event-listeners";
import { FirebaseService } from "./controllers/FirebaseService";
import AppInfo from "./pages/profile/AppInfo";
import Timeline from "./pages/timeline/Timeline";
import ReadEvidence from "./pages/evidence/ReadEvidence";
import { NavigationBar } from "@hugotomazi/capacitor-navigation-bar";
import Info from "./pages/dashboard/Info";
import DataController from "./controllers/DataController";
import ProtectedRoute from "./classes/ProtectedRoute";
import Account from "./pages/profile/Account";
import Accessibility from "./pages/profile/Accessibility";
import NOSOverview from "./pages/dashboard/NOSOverview";
import { Capacitor } from "@capacitor/core";
import Dashboard from "./pages/dashboard/Dashboard";
import { Keyboard, KeyboardInfo } from "@capacitor/keyboard";
import { IEvidence, ProgramData } from "./Interfaces";
import ProgramHome from "./pages/programs/ProgramHome";
import ProgramSkillPage from "./pages/programs/ProgramSkillPage";
import ProgramDetails from "./pages/programs/ProgramDetails";
import ProgressCheckPage from "./pages/programs/ProgressCheckPage";
import Comments from "./pages/programs/Comments";
import * as ProgramUtils from "./utils/programUtils";
import StandardPage from "./pages/programs/StandardPage";
import ProgramDataInfo from "./pages/dashboard/ProgramDataInfo";
import WellbeingActivity from "./pages/programs/WellbeingActivity";
import LearningOutcomesPage from "./pages/programs/LearningOutcomesPage";
import WellbeingResults from "./pages/programs/WellbeingResults";
import MtsDetailsSetup from "./pages/programs/MtsDetailsSetup";

const Tabs: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const popoverRef = useRef<HTMLIonPopoverElement>(null);

  const user = useRecoilValue(userAtom);

  const [reflectionModalVisible, setReflectionModalVisible] = useState<boolean>(false);
  const [compactTabBar, setCompactTabBar] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>("dashboard");
  const [tabBarVisible, setTabBarVisible] = useState<boolean>(true);
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);

  const evidence = useRecoilValueLoadable<IEvidence[]>(evidenceAtom);
  const userPrograms = useRecoilValue<ProgramData[] | any[]>(userProgramsAtom);

  const [loggedIn, setLoggedIn] = useState<boolean>(!_.isEmpty(user));

  const [keyboardActive, setKeyboardActive] = useState<boolean>(false);

  useEffect(() => {
    setLoggedIn(!_.isEmpty(user));
  }, [user]);

  useEffect(() => {
    const windowResized = () => {
      if (window.innerWidth < 600) {
        setCompactTabBar(true);
      } else {
        setCompactTabBar(false);
      }
    };

    window.addEventListener("resize", windowResized);

    windowResized();

    return () => {
      window.removeEventListener("resize", windowResized);
    };
  }, []);

  useEffect(() => {
    if (Capacitor.getPlatform() === "android") {
      Keyboard.addListener("keyboardWillShow", (info: KeyboardInfo) => {
        setKeyboardActive(true);
      });

      Keyboard.addListener("keyboardWillHide", () => {
        setKeyboardActive(false);
      });
    }
  }, []);

  useEffect(() => {
    const setNavbarColors = async (): Promise<void> => {
      if (Capacitor.getPlatform() === "android") {
        try {
          NavigationBar.setColor({ color: "#F7FAF8" });
        } catch (error) {}
      }
    };

    setNavbarColors();
  }, []);

  useEffect(() => {
    const getInitialTab = () => {
      const content = document.getElementsByTagName("ion-tab-bar")[0];
      const initial = content && content.selectedTab;

      if (initial) {
        setSelectedTab(initial);
      }
    };

    getInitialTab();
  }, []);

  useEffect(() => {
    EventRegister.addEventListener("evidence-modal/add-evidence-guideline", () => setReflectionModalVisible(true));
    EventRegister.addEventListener("evidence-modal/add-evidence-timeline", () => setReflectionModalVisible(true));
    EventRegister.addEventListener("evidence-modal/add-evidence-deeplink", () => {
      setReflectionModalVisible(true);
    });
    EventRegister.addEventListener("evidence/evidence-card-clicked", () => setReflectionModalVisible(true));
    EventRegister.addEventListener("timeline/edit-evidence-clicked", () => setReflectionModalVisible(true));
    EventRegister.addEventListener("evidence/empty-add-clicked", () => setReflectionModalVisible(true));
    EventRegister.addEventListener("evidence/add-program-evidence", () => setReflectionModalVisible(true));
    EventRegister.addEventListener("evidence/add-skill-evidence", () => setReflectionModalVisible(true));
    EventRegister.addEventListener("evidence/add-progress-check-evidence", () => setReflectionModalVisible(true));
    EventRegister.addEventListener("tab-bar/visibility-changed", (value) => setTabBarVisible(value));

    return () => {
      EventRegister.removeEventListener("evidence-modal/add-evidence-guideline");
      EventRegister.removeEventListener("evidence-modal/add-evidence-timeline");
      EventRegister.removeEventListener("evidence-modal/add-evidence-deeplink");
      EventRegister.removeEventListener("evidence/evidence-card-clicked");
      EventRegister.removeEventListener("timeline/edit-evidence-clicked");
      EventRegister.removeEventListener("evidence/empty-add-clicked");
      EventRegister.removeEventListener("evidence/add-program-evidence");
      EventRegister.removeEventListener("evidence/add-skill-evidence");
      EventRegister.removeEventListener("evidence/add-progress-check-evidence");
      EventRegister.removeEventListener("tab-bar/visibility-changed");
    };
  }, []);

  useEffect(() => {
    EventRegister.addEventListener("profile/view-profile", () => {
      history.replace("/profile");
      setSelectedTab("profile");
    });

    EventRegister.addEventListener("profile/edit-profile", () => {
      history.push("/profile/edit");
    });

    EventRegister.addEventListener("evidence/filter-hcpc", () => {
      history.replace("/evidence");
      setSelectedTab("evidence");
    });

    EventRegister.addEventListener("evidence/evidence-deleted", () => {
      try {
        history.go(-1);
      } catch (error) {
        console.log(error);
      }
    });

    return () => {
      EventRegister.removeEventListener("profile/view-profile");
      EventRegister.removeEventListener("profile/edit-profile");
      EventRegister.removeEventListener("evidence/filter-hcpc");
      EventRegister.removeEventListener("evidence/evidence-deleted");
    };
  }, [history]);

  useEffect(() => {
    const getSelectedTab = (): void => {
      const pathname = location.pathname;

      if (pathname.includes("/dashboard")) {
        setSelectedTab("dashboard");
      } else if (pathname.includes("/timeline")) {
        setSelectedTab("timeline");
      } else if (pathname.includes("/evidence")) {
        setSelectedTab("evidence");
      } else if (pathname.includes("/guidelines")) {
        setSelectedTab("guidelines");
      } else if (pathname.includes("/profile")) {
        setSelectedTab("profile");
      }
    };

    getSelectedTab();
  }, [location]);

  const getIconForTab = (tab: string): string => {
    if (selectedTab === tab) {
      if (tab === "dashboard") {
        return TabBar_Dash_Active;
      }
      if (tab === "guidelines") {
        return Tab_Guidelines_Active;
      }
      if (tab === "evidence") {
        return Tab_Evidence_Active;
      }
      if (tab === "timeline") {
        return Tab_Timeline_Active;
      }
      if (tab === "profile") {
        return Tab_Profile_Active;
      }
    } else {
      if (tab === "dashboard") {
        return TabBar_Dash_Inactive;
      }
      if (tab === "guidelines") {
        return Tab_Guidelines_Inactive;
      }
      if (tab === "evidence") {
        return Tab_Evidence_Inactive;
      }
      if (tab === "timeline") {
        return Tab_Timeline_Inactive;
      }
      if (tab === "profile") {
        return Tab_Profile_Inactive;
      }
    }

    return TabBar_Dash_Active;
  };

  const tabButtonPressed = (tab: string): void => {
    if (selectedTab === tab) {
      try {
        const element = document.getElementById(`${tab}ContentContainer`);

        element?.scrollIntoView({ behavior: "smooth" });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const tabIsActive = (tab: string): boolean => {
    return selectedTab === tab;
  };

  const tabChanged = (event: any): void => {
    setSelectedTab(event.detail.tab);
    FirebaseService.logEvent("tab_pressed", { tab: event.detail.tab });
  };

  const addEvidencePressed = async (): Promise<void> => {
    await FirebaseService.logEvent("add_evidence_pressed", {
      tab: selectedTab,
      type: "add_evidence_fab",
    });
  };

  const addProgramEvidencePressed = async (program: ProgramData): Promise<void> => {
    await FirebaseService.logEvent("add_program_evidence_pressed", {
      tab: selectedTab,
      program: program.Title,
    });
  };

  const toggleModalVisible = (value: boolean): void => {
    setReflectionModalVisible(value);
  };

  const evidenceButtonPressed = () => {
    setPopoverVisible(false);
    toggleModalVisible(true);
  };

  const evidenceFABPressed = async (): Promise<void> => {
    if (userPrograms.length > 0) {
      setPopoverVisible(true);
    } else {
      addEvidencePressed();
      toggleModalVisible(true);
    }
  };

  const addProgramEvidence = (program: ProgramData): void => {
    addProgramEvidencePressed(program);

    const competences = program.CompetenceActivity ?? [];
    const progressChecks = program.ProgressChecks ?? [];
    const evidenceDefinitions = program["Evidence Definitions"] ?? [];
    const mandatoryStandards = ProgramUtils.getMandatoryStandards(user, program);
    const optionalStandards = ProgramUtils.getOptionalStandards(user, program);

    const data = {
      program,
      ...(competences.length > 0 && { competences }),
      ...(evidenceDefinitions.length > 0 && {
        evidenceDefinitions: evidenceDefinitions[0],
      }),
      ...(progressChecks.length > 0 && { progressChecks }),
      ...(mandatoryStandards.length > 0 && { mandatoryStandards }),
      ...(optionalStandards.length > 0 && { optionalStandards }),
      skill: null,
    };

    closeFABPopover();
    EventRegister.emit("evidence/add-program-evidence", data);
  };

  const closeFABPopover = (): void => {
    setPopoverVisible(false);
  };

  return (
    <IonContent id="contentMain" className="tabsContainer">
      <div id="deep-linking-active" className="hidden" />
      <EvidenceModal visible={reflectionModalVisible} closeModal={(value) => toggleModalVisible(value)} />
      <IonTabs>
        <IonRouterOutlet id="main" mode="ios">
          <Route exact path="/:tab(dashboard)">
            <ProtectedRoute outlet={<Dashboard />} isAuthenticated={loggedIn} authenticationPath="/login" />
          </Route>
          <Route exact path="/:tab(dashboard)/info/:pageId" render={() => <Info />} />
          <Route exact path="/:tab(dashboard)/nos-overview" render={() => <NOSOverview />} />
          <Route exact path="/:tab(dashboard)/program/:programId" render={() => <ProgramHome />} />
          <Route exact path="/:tab(dashboard)/program/:programId/:skillId" render={() => <ProgramSkillPage />} />
          <Route
            exact
            path="/:tab(dashboard)/program/:programId/standard/:standardId"
            render={() => <StandardPage />}
          />
          <Route exact path="/:tab(dashboard)/program/:programId/read/:evidenceId" render={() => <ReadEvidence />} />
          <Route exact path="/:tab(dashboard)/program/:programId/details" render={() => <ProgramDetails />} />
          <Route exact path="/:tab(dashboard)/program/:programId/mts-details" render={() => <MtsDetailsSetup />} />
          <Route
            exact
            path="/:tab(dashboard)/program/:programId/:skillId/read/:evidenceId"
            render={() => <ReadEvidence />}
          />
          <Route
            exact
            path="/:tab(dashboard)/program/:programId/progress-check/:progressCheckId"
            render={() => <ProgressCheckPage />}
          />
          <Route
            exact
            path="/:tab(dashboard)/program/:programId/progress-check/:progressCheckId/comp/:compId"
            render={() => <LearningOutcomesPage />}
          />
          <Route
            exact
            path="/:tab(dashboard)/program/:programId/progress-check/:progressCheckId/comments"
            render={() => <Comments />}
          />
          <Route
            exact
            path="/:tab(dashboard)/program/:programId/progress-check/:progressCheckId/read/:evidenceId"
            render={() => <ReadEvidence />}
          />
          <Route
            exact
            path="/:tab(dashboard)/program/:programId/progress-check/:progressCheckId/wellbeing-activity/:competenceId"
            render={() => <WellbeingActivity />}
          />
          <Route
            exact
            path="/:tab(dashboard)/program/:programId/progress-check/:progressCheckId/wellbeing-activity/:competenceId/results/:evidenceId"
            render={() => <WellbeingResults />}
          />
          <Route exact path="/:tab(evidence)" render={() => <Evidence />} />
          <Route exact path="/:tab(evidence)/read/:evidenceId" render={() => <ReadEvidence />} />
          <Route exact path="/:tab(timeline)" render={() => <Timeline />} />
          <Route exact path="/:tab(profile)" render={() => <Profile />} />
          <Route exact path="/:tab(profile)/edit" render={() => <EditRole />} />
          <Route exact path="/:tab(profile)/app-info" render={() => <AppInfo />} />
          <Route exact path="/:tab(profile)/accessibility" render={() => <Accessibility />} />
          <Route exact path="/:tab(profile)/account" render={() => <Account />} />
          <Route exact path="/login" render={() => <Redirect to="/onboarding" />} />
          <Route exact path="/onboarding/login" render={() => <Redirect to="/dashboard" />} />
          <Route exact path="/onboarding/register" render={() => <Redirect to="/dashboard" />} />
          <Route exact path="/onboarding" render={() => <Redirect to="/dashboard" />} />
          <Route exact path="/:tab(dashboard)/program-data-info" render={() => <ProgramDataInfo />} />
          <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
        </IonRouterOutlet>
        <IonTabBar
          slot="bottom"
          className={`portfolioTabBar ${tabBarVisible ? "" : "tabBarHidden"}`}
          onIonTabsDidChange={(event) => tabChanged(event)}
          selectedTab={selectedTab}
        >
          <IonTabButton
            tab="dashboard"
            href="/dashboard"
            mode="ios"
            onClick={() => tabButtonPressed("dashboard")}
            layout={compactTabBar ? "icon-top" : "icon-start"}
          >
            <IonImg src={getIconForTab("dashboard")} className={compactTabBar ? "tabBarIconTop" : "tabBarIconStart"} />
            <IonLabel
              className={`${compactTabBar ? "tabBarLabelTop" : "tabBarLabelStart"} ${tabIsActive("dashboard") ? "tabBarLabelActive" : ""}`}
            >
              {"Dashboard"}
            </IonLabel>
          </IonTabButton>
          <IonTabButton
            tab="timeline"
            href="/timeline"
            mode="ios"
            onClick={() => tabButtonPressed("timeline")}
            layout={compactTabBar ? "icon-top" : "icon-start"}
          >
            <IonImg src={getIconForTab("timeline")} className={compactTabBar ? "tabBarIconTop" : "tabBarIconStart"} />
            <IonLabel
              className={`${compactTabBar ? "tabBarLabelTop" : "tabBarLabelStart"} ${tabIsActive("timeline") ? "tabBarLabelActive" : ""}`}
            >
              {"Timeline"}
            </IonLabel>
          </IonTabButton>
          <IonTabButton
            tab="evidence"
            href="/evidence"
            mode="ios"
            onClick={() => tabButtonPressed("evidence")}
            layout={compactTabBar ? "icon-top" : "icon-start"}
          >
            <IonImg src={getIconForTab("evidence")} className={compactTabBar ? "tabBarIconTop" : "tabBarIconStart"} />
            <IonLabel
              className={`${compactTabBar ? "tabBarLabelTop" : "tabBarLabelStart"} ${tabIsActive("evidence") ? "tabBarLabelActive" : ""}`}
            >
              {"Evidence"}
            </IonLabel>
          </IonTabButton>
          <IonTabButton
            tab="profile"
            href="/profile"
            mode="ios"
            onClick={() => tabButtonPressed("profile")}
            layout={compactTabBar ? "icon-top" : "icon-start"}
          >
            <IonImg src={getIconForTab("profile")} className={compactTabBar ? "tabBarIconTop" : "tabBarIconStart"} />
            <IonLabel
              className={`${compactTabBar ? "tabBarLabelTop" : "tabBarLabelStart"} ${tabIsActive("profile") ? "tabBarLabelActive" : ""}`}
            >
              {"Profile"}
            </IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
      {!keyboardActive && (
        <div className="fabContainer" style={{ maxWidth: DataController.isWeb() ? 980 : undefined }}>
          <IonFab
            className={`tabBarFab ${tabBarVisible ? "" : "tabBarHidden"}`}
            vertical="bottom"
            id="evidenceFab"
            horizontal="end"
            slot="fixed"
          >
            <IonFabButton onClick={() => evidenceFABPressed()} activated={popoverVisible}>
              <IonIcon icon={add}></IonIcon>
            </IonFabButton>
          </IonFab>
          {userPrograms.length > 0 && (
            <IonPopover
              id="fabPopover"
              className="fabPopover"
              ref={popoverRef}
              trigger="evidenceFab"
              mode="ios"
              isOpen={popoverVisible}
              onDidDismiss={() => setPopoverVisible(false)}
              animated={true}
              reference="trigger"
              side="top"
              keepContentsMounted={true}
              alignment="end"
              backdropDismiss={true}
            >
              <IonContent>
                <div>
                  <IonItem button detail={false} className="fabPopoverIonItem" onClick={() => evidenceButtonPressed()}>
                    <div className="fabPopoverIonLabel">{"Add evidence"}</div>
                  </IonItem>
                  {userPrograms.map((program: ProgramData, index) => {
                    return (
                      <IonItem
                        key={program.id}
                        button
                        detail={false}
                        disabled={ProgramUtils.disableFABProgramButton(program, evidence.contents, user)}
                        className="fabPopoverIonItem"
                        onClick={() => addProgramEvidence(program)}
                      >
                        <div className="fabPopoverIonLabel">{`Add ${program.EvidenceButtonText ?? program.Title}`}</div>
                      </IonItem>
                    );
                  })}
                </div>
              </IonContent>
            </IonPopover>
          )}
        </div>
      )}
    </IonContent>
  );
};

export default Tabs;
