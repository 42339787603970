import { useEffect, useState } from "react";
import _ from "lodash";

import "./CommonComponents.css";
import {
  AllProgressCheckStatuses,
  CompetenceActivityClass,
  EvidencePart,
  IClassSubscription,
  IEvidence,
  IUser,
  IUserProgramCertificate,
  ProgramCertificate,
  ProgramData,
  ProgramSkill,
  Required,
} from "../../Interfaces";
import { useHistory } from "react-router";
import { useRecoilStateLoadable, useRecoilValueLoadable } from "recoil";
import { evidenceAtom, progressCheckDataAtom, userAtom } from "../../state/State";
import { IonButton, IonImg, IonItem } from "@ionic/react";
import { Button_Disclosure_Right } from "../../assets/images";
import { isAfter, isSameDay, isSameMonth } from "date-fns";
import DataController from "../../controllers/DataController";
import AuthenticationController from "../../controllers/AuthenticationController";
import { FirebaseService } from "../../controllers/FirebaseService";
import * as ProgramUtils from "../../utils/programUtils";
import ProgramCardPreceptorshipProgress from "../programs/ProgramCardPreceptorshipProgress";
import ProgramRolesDisplay from "../programs/ProgramRolesDisplay";
import EprrOverview from "../programs/EprrOverview";
import MtsOverview from "../programs/MtsOverview";
import { useProgramData } from "../../hooks/data/useProgramData";

type Props = {
  userObject: IUser | null;
  programID: string;
  id?: any;
};

const ProgramCard: React.FC<Props> = (props) => {
  const history = useHistory();

  const { userPrograms } = useProgramData();

  const program = userPrograms.find((item) => item.ID === props.programID)!;
  const [user, setUser] = useRecoilStateLoadable<IUser | null>(userAtom);
  const evidence = useRecoilValueLoadable<IEvidence[] | null>(evidenceAtom);
  const allProgressCheckData = useRecoilValueLoadable<AllProgressCheckStatuses[] | null>(progressCheckDataAtom);

  const [programEvidence, setProgramEvidence] = useState<IEvidence[]>([]);
  const [draftEvidenceCount, setDraftEvidenceCount] = useState(0);

  // Competence based program (e.g. SWAST HART)
  const [requiredSkills, setRequiredSkills] = useState<ProgramSkill[] | any[]>([]);
  const [competenceCertificates, setCompetenceCertificates] = useState<[] | any[]>([]);
  // Roles base program (e.g. SWAST HART & EPRR)
  const [programCertificates, setProgramCertificates] = useState<IUserProgramCertificate[] | any[]>([]);
  const [userRoles, setUserRoles] = useState<string[] | null>(null);

  const [programStarted, setProgramStarted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getProgramStarted = async (): Promise<void> => {
      const subscriptions = user.contents?.userData.subscriptions || [];
      const programSubscriptionIDs = program?.Subscription || [];

      const programSubscription: IClassSubscription = subscriptions.find((item: IClassSubscription | any) => {
        const subscriptionPrograms: string[] = item.ParaFolioPrograms;

        return (
          subscriptionPrograms?.some((_sub) => program?.Subscription?.includes(_sub)) ||
          programSubscriptionIDs.includes(`${item.SubscriptionID}`)
        );
      });

      // console.log("programSubscription", programSubscription);

      if (programSubscription && programSubscription.App_Data) {
        let json: any = {};

        try {
          json = JSON.parse(programSubscription.App_Data);
        } catch (error) {
          console.log(error);
        }

        // console.log("json", json);
        // console.log("program", program);

        if (json[`${program?.ID}`]?.startDate) {
          setProgramStarted(true);
        }
      }

      setLoading(false);
    };

    if (user.state === "hasValue" && program) {
      getProgramStarted();
    }
  }, [user, program]);

  useEffect(() => {
    const getRolesForProgram = (): void => {
      const roles = user.state === "hasValue" && _.cloneDeep(user.contents?.programRoles);

      if (roles) {
        const programRoles = roles.find((item) => item.programName === program!.Name);

        if (programRoles) {
          if (ProgramUtils.usingDescriptiveProgramRolesFormat(program!.ID)) {
            const roles = programRoles.programRoles.map((r) => r.roleName);
            setUserRoles(roles);
          } else {
            setUserRoles(programRoles.programRoles);
          }
        }
      }
    };

    if (user.state === "hasValue" && user.contents && program) {
      getRolesForProgram();
    }
  }, [user, program]);

  useEffect(() => {
    const getEvidenceForProgram = (): void => {
      let evidenceForProgram: IEvidence[] = [];

      const draftCount = evidence.contents
        ? evidence.contents?.filter((evidence: IEvidence) => evidence.draft).length
        : 0;
      evidenceForProgram = evidence.contents?.filter((evidence: IEvidence) => {
        const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
        const programName = evidenceJSON?.programInfo?.program || "";

        const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

        return programName === program?.Name && onHoldReset !== 1 && evidence.draft !== true;
      });

      setDraftEvidenceCount(draftCount);
      setProgramEvidence(evidenceForProgram);
    };

    if (evidence.state === "hasValue" && program) {
      getEvidenceForProgram();
    }
  }, [evidence, program]);

  useEffect(() => {
    const getProgramRequiredSkills = (): void => {
      if (program) {
        const competences = program.CompetenceActivity ?? [];

        const filteredCompetences = competences.filter(
          (item: any): item is CompetenceActivityClass => item && item !== undefined
        );

        const mappedCompetences = filteredCompetences.map((item: CompetenceActivityClass) => item && item.Skills);
        const skills = mappedCompetences.filter((item) => item !== undefined);
        const flattenedSkills = _.flatten(skills);

        const required = flattenedSkills.filter(
          (item: any): item is ProgramSkill =>
            item && item.numberRequiredInDuration && parseInt(item.numberRequiredInDuration, 10) > 0
        );

        if (required) {
          setRequiredSkills(required);
        }
      }
    };

    getProgramRequiredSkills();
  }, [program]);

  useEffect(() => {
    const programOrder = (item: IUserProgramCertificate): number => {
      const certificateName = item?.certificate?.certificateType;
      const mappedCertificate = program?.Certificates?.find(
        (_cert: ProgramCertificate) => _cert.Name.toUpperCase() === certificateName?.toUpperCase()
      );

      return mappedCertificate?.Required === Required.Mandatory ? 2 : 1;
    };

    const getProgramCertificateInfo = (): void => {
      let _programCertificates: IUserProgramCertificate[] | any[] = [];

      if (user.contents) {
        _programCertificates = user.contents.programCertificates || [];
        _programCertificates = _programCertificates.filter((item) => item.programData.programName === program?.Name);
      }

      setProgramCertificates(
        _.orderBy(_programCertificates, [programOrder, (item) => item.certificate.certificateType], ["desc", "asc"])
      );
    };

    const getCompetenceCertificateInfo = (): void => {
      if (program) {
        let _programCertificates: IUserProgramCertificate[] | any[] = [];

        if (user.contents) {
          _programCertificates = user.contents.programCertificates || [];
          _programCertificates = _programCertificates.filter((item) => item.programData.programName === program?.Name);

          // console.log('getUserProgramCertificates', _programCertificates);
        }

        const competences = program.CompetenceActivity ?? [];
        const filteredCompetences = competences.filter(
          (item) => item && item.Certificates && item.Certificates.length > 0
        );

        const mappedCompetences = filteredCompetences.map((item: CompetenceActivityClass) => item && item.Certificates);
        const array = mappedCompetences.filter((item) => item !== undefined);
        let _certificates = _.flatten(array);

        _certificates = _certificates.sort((a: any, b: any) => {
          const certificateA = _programCertificates.find(
            (item: IUserProgramCertificate) => item.programData.competenceName === a.Name
          );
          const certificateB = _programCertificates.find(
            (item: IUserProgramCertificate) => item.programData.competenceName === b.Name
          );

          if (certificateA && certificateB) {
            const dateA = new Date(certificateA.issueDate);
            const dateB = new Date(certificateB.issueDate);

            if (isSameDay(dateA, dateB)) {
              return 0;
            } else if (isAfter(dateA, dateB)) {
              return 1;
            } else {
              return -1;
            }
          } else if (certificateA) {
            return -1;
          } else if (certificateB) {
            return 1;
          } else {
            return 0;
          }
        });

        _certificates = _certificates.slice(0, 3);
        setCompetenceCertificates(_certificates);
      }
    };

    getCompetenceCertificateInfo();
    getProgramCertificateInfo();
  }, [program, user]);

  const startProgram = async (): Promise<void> => {
    if (user.state === "hasValue" && user.contents && program) {
      const subscriptions = user.contents?.userData.subscriptions || [];
      const programSubscriptionIDs = program?.Subscription || [];

      const programSubscription: IClassSubscription = subscriptions.find((item: IClassSubscription | any) => {
        const subscriptionPrograms: string[] = item.ParaFolioPrograms;

        return (
          subscriptionPrograms?.some((_sub) => program?.Subscription?.includes(_sub)) ||
          programSubscriptionIDs.includes(`${item.SubscriptionID}`)
        );
      });

      // console.log(programSubscription);

      if (programSubscription) {
        if (programSubscription.App_Data) {
          let json: any = {};

          try {
            json = JSON.parse(programSubscription.App_Data);
          } catch (error) {
            console.log(error);
          }

          // console.log(json);

          let data = {
            ...json,
            [`${program.ID}`]: {
              startDate: new Date().getTime(),
            },
          };

          // console.log('setFirstScreenShow', success);

          const success = await AuthenticationController.appendSubAppData(
            user.contents?.userData.contactID,
            user.contents?.token!,
            programSubscription.SubscriberID,
            data
          );

          if (success) {
            let userToSave = await AuthenticationController.getUserData(user.contents);

            if (userToSave) {
              setUser(userToSave);
            }
          }
        } else {
          let data = {
            [`${program.ID}`]: {
              startDate: new Date().getTime(),
            },
          };

          const success = await AuthenticationController.appendSubAppData(
            user.contents?.userData.contactID,
            user.contents?.token!,
            programSubscription.SubscriberID,
            data
          );

          if (success) {
            let userToSave = await AuthenticationController.getUserData(user.contents);

            if (userToSave) {
              setUser(userToSave);
            }
          }
        }
      }

      FirebaseService.logEvent("program_card_pressed", {
        start: true,
        program: program?.Name,
      });

      goToProgram();
    }
  };

  const goToProgram = (): void => {
    const programID = program?.ID;

    FirebaseService.logEvent("program_card_pressed", {
      program: program?.Name,
    });

    history.push(`/dashboard/program/${programID}`);
  };

  const trustBackgroundColourVariants: any = {
    SWASFT: "bg-SWASFT",
    NWAS: "bg-NWAS",
    LAS: "bg-LAS",
  };

  if (loading) {
    return null;
  }

  if (!programStarted) {
    return (
      <div className="programPlaceholderCard">
        <div className={`programPlaceholderCardBackground ${ProgramUtils.getProgramCardBackground(program?.Name!)}`} />
        <div className="programPlaceholderCardInner">
          <div className="overviewHeader" style={{ marginTop: -3 }}>
            <div className="flex justify-between">
              {program?.Trust && (
                <div
                  className={`programTrustPill programTrustPillStarted ${trustBackgroundColourVariants[program.TrustName!]}`}
                >
                  {program.Trust}
                </div>
              )}
              {program.showBetaLabel && (
                <div className="text-white bg-black px-1 rounded-[2px] font-semibold text-13px flex justify-center items-center">
                  {"BETA"}
                </div>
              )}
            </div>
            <div className="programCardTitle" style={{ color: "#FFFFFF" }}>
              {`Set up your ${program?.Title}`}
            </div>
            <div className="programCardSubtitle">{program?.DashboardDescription}</div>
          </div>
          <IonButton onClick={() => startProgram()} className="programGetStartedButton">
            <div>{"Get started"}</div>
          </IonButton>
        </div>
      </div>
    );
  }

  return (
    <IonItem button detail={false} onClick={() => goToProgram()} className="dashboardCard programCard">
      <div className="programCardInner">
        <div className="overviewHeader">
          <div className="flex justify-between">
            {program?.Trust && (
              <div className={`programTrustPill ${trustBackgroundColourVariants[program.TrustName!]}`}>
                {program.Trust}
              </div>
            )}
            {program.showBetaLabel && (
              <div className="text-white bg-black px-1 rounded-[2px] font-semibold text-13px flex justify-center items-center">
                {"BETA"}
              </div>
            )}
          </div>
          <div className="programCardTitleContainer">
            <div className="programCardTitle">{program?.Title || program?.Name || "Unknown"}</div>
            <IonImg src={Button_Disclosure_Right} className="programCardTitleDisclosure" />
          </div>
        </div>
        {requiredSkills.map((item: ProgramSkill) => {
          const competence = program?.CompetenceActivity!.find(
            (_competence: any) => _competence.id === item["Competence/Activity"][0]
          );

          const evidenceDefinitions = competence!["Evidence Definitions"][0];
          const _evidenceParts = evidenceDefinitions?.["Evidence parts"] || [];
          const evidencePartWithSkills = _evidenceParts.filter(
            (item: EvidencePart) => item.Skills && item.Skills.length > 0
          )[0];

          // let skillsWithinEvidence = evidencePartWithSkills.Skills;

          const evidenceWithSkills = programEvidence?.filter((_evidence) => {
            const evidenceJSON = JSON.parse(_evidence.evidenceJSON);
            const part = evidenceJSON[evidencePartWithSkills.Name];

            const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

            return part !== undefined && onHoldReset !== 1 && _evidence.draft !== true;
          });

          const total = item.numberRequiredInDuration || "0";
          const count =
            evidenceWithSkills?.filter((_evidence) => {
              const evidenceJSON = JSON.parse(_evidence.evidenceJSON);
              const part = evidenceJSON[evidencePartWithSkills.Name];
              const achieved = part?.includes(item.Name);

              return achieved && isSameMonth(new Date(), new Date(_evidence.date));
            }).length || 0;
          const array = Array.from(Array(parseInt(total, 10)).keys());

          return (
            <div className="programCardRequiredSkillContainer" key={item.id}>
              <div className="programCardRequiredSkillHeader">
                {`${item.Name} practiced this ${item.durationDescription || "period"} (mandatory)`}
              </div>
              <div className="programCardRequiredSkillResult">
                <div
                  className={`programCardRequiredSkillAchieved ${count > 0 && "programCardRequiredSkillAchievedGreen"}`}
                >
                  {`${count}`}
                </div>
                <div className="programCardRequiredSkillNeeded">
                  &nbsp;
                  {"/ "}
                  {parseInt(total, 10)}
                </div>
              </div>
              <div className="programCardRequiredGraph">
                {array.map((value, index) => {
                  const isEnd = index === array.length - 1;
                  const completed = index < count;

                  return (
                    <div className="programSegmentContainer" key={index}>
                      <div className={`${completed ? "programSegmentFilled" : "programSegmentEmpty"}`} />
                      {!isEnd && <div className="programGraphSeparator" />}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        {competenceCertificates.length > 0 && (
          <div>
            <div className="programCardCertificateHeader">
              <div className="programCardCertificateHeaderTextBold">{"Upcoming renewals"}</div>
              <div className="programCardCertificateHeaderText">{"Expiry date"}</div>
            </div>
            {competenceCertificates.map((item: ProgramCertificate) => {
              const userCertificate = programCertificates.find(
                (cert: IUserProgramCertificate) =>
                  cert.programData.programName === program?.Name && cert.programData.competenceName === item.Name
              );
              let evidenceForCompetence: IEvidence[] = [];

              evidenceForCompetence = evidence.contents?.filter((evidence: IEvidence) => {
                const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
                const competenceName = evidenceJSON?.programInfo?.competence || "";

                const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

                return competenceName === item.Name && onHoldReset !== 1 && evidence.draft !== true;
              });

              return (
                <div key={item.id}>
                  <div className="programCardCertificateContainer">
                    <div className="programCardCertificateTitleContainer">
                      <div className="programCardCertificateTitle">{item.Name}</div>
                      <div className="programCardCertificateSubtitle">{`${evidenceForCompetence?.length || 0} evidence`}</div>
                    </div>
                    {item.Duration && (
                      <div className={DataController.getCertificatePillContainer(userCertificate, item.Duration)}>
                        {DataController.getCertificatePillString(userCertificate, item.Duration)}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {ProgramUtils.showProgramCertificates(program?.ID!) && (
          <>
            {programCertificates.map((item: IUserProgramCertificate) => {
              return (
                <div key={item.certificate?.certificateType}>
                  <div className="programCardInfoHeader" style={{ marginBottom: 4 }}>
                    <div className="programCardInfoHeaderText">{`${item.programData.certificateType} certificate`}</div>
                    <div className="programCardInfoHeaderBold">{item.certificate?.name}</div>
                  </div>
                </div>
              );
            })}
          </>
        )}
        {!ProgramUtils.isMTS(program.ID) && userRoles && (
          <ProgramRolesDisplay userRoles={userRoles} programId={program?.ID!} />
        )}
        {ProgramUtils.showEvidenceCount(program?.ID!) && (
          <div>
            <div className="programCardInfoHeader" style={{ marginBottom: 0 }}>
              <div className="programCardInfoHeaderText">{"Evidence"}</div>
              <div className="programCardInfoEvidenceCount">{programEvidence?.length}</div>
            </div>
          </div>
        )}
        {ProgramUtils.showProgressCheckProgressOnDashboard(program?.ID!) && (
          <ProgramCardPreceptorshipProgress
            progressCheckData={allProgressCheckData.contents}
            program={program}
            progressChecks={program?.ProgressChecks || []}
            programEvidence={programEvidence}
          />
        )}
        {ProgramUtils.isEPRR(program?.ID) && programStarted && <EprrOverview />}
        {ProgramUtils.isMTS(program?.ID) && programStarted && <MtsOverview />}
      </div>
    </IonItem>
  );
};

export default ProgramCard;
