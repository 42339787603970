import { ISegmentedProgressBar } from "../../types/Components";

const SegmentedProgressBar: React.FC<ISegmentedProgressBar> = (props) => {
  return (
    <div className="mt-4">
      <div className="text-grey-90 text-acc-13px font-normal">{props.title}</div>
      <div className="mt-2">
        <span
          className={`font-bold text-acc-34px text-grey-60 ${props.numerator > 0 && "text-progress-compliant-text"}`}
        >
          {props.numerator}
        </span>
        <span className="text-grey-60 font-bold text-acc-15px">{` / ${props.denominator}`}</span>
      </div>
      <div className="flex gap-1 mt-2">
        {props.items.map((item, index) => (
          <div
            key={item.id}
            className={`flex-1 h-[4px] bg-program-card-progress-marker rounded ${index < props.numerator && "bg-progress-compliant-text"}`}
          />
        ))}
      </div>
    </div>
  );
};

export default SegmentedProgressBar;
