import { IonItem } from "@ionic/react";
import {
  CompetenceActivityClass,
  CompMultiCollapsibleItem,
  EvidencePart,
  LearningObjective,
  ProgramData,
} from "../../../Interfaces";
import Collapsible from "react-collapsible";
import { MouseEvent, useEffect, useState } from "react";
import {
  Button_Checkbox_Active,
  Button_Checkbox_Inactive,
  Button_Collapse,
  Button_Expand,
} from "../../../assets/images";
import { View, Text } from "@react-pdf/renderer";

type Props = {
  definition: EvidencePart;
  onValueChanged?: (value: any) => void;
  data?: any;
  isDisplay?: boolean;
  isPDF?: boolean;
  pdfStyles: any;
  initialValue: {
    compId: string;
    compName: string;
    isSelected: boolean;
    selectedOutcomes: { id: string; name: string }[];
  }[];
  program?: ProgramData;
  learningOutcomes: LearningObjective[];
  progressCheckCompetence?: CompetenceActivityClass;
  currentProgressCheckId: string;
};

const CompMultiSelectList: React.FC<Props> = ({
  definition,
  onValueChanged,
  data,
  isDisplay,
  isPDF,
  pdfStyles,
  initialValue,
  program,
  learningOutcomes,
  progressCheckCompetence,
  currentProgressCheckId,
}) => {
  const currentProgressCheck = program?.ProgressChecks?.find((pc) => pc.ID === currentProgressCheckId);
  const comps =
    currentProgressCheck?.["Competence/Activity"].filter(
      (comp) => !comp.isFinalReview && comp["Learning Objective"]?.length !== 0
    ) ?? [];

  const [collapsibleItems, setCollapsibleItems] = useState<CompMultiCollapsibleItem[]>(setInitialValue);

  function handleToggleAll(index: number) {
    const copy = [...collapsibleItems];
    if (copy[index].selectedOutcomes.length === copy[index].selectedOutcomesLimit) {
      copy[index].selectedOutcomes = [];
      copy[index].isSelected = false;
    } else {
      const currentComp = comps.find((c) => c.ID === copy[index].compId);
      const newLearningOutcomes = currentComp?.["Learning Objective"] ?? [];
      if (currentComp) {
        copy[index].selectedOutcomes = newLearningOutcomes.map((lo) => ({ id: lo.ID, name: lo.Name }));
        copy[index].isSelected = true;
      }
    }

    setCollapsibleItems(copy);
    updateEvidenceJson(copy);
  }

  function setInitialValue(): CompMultiCollapsibleItem[] {
    const defaultValues = comps.map((comp) => ({
      compId: comp.ID,
      compName: comp.Name,
      isSelected: false,
      isOpen: false,
      selectedOutcomes: [],
      selectedOutcomesLimit: comp["Learning Objective"]?.length ?? 0,
    }));

    if (initialValue && initialValue.length > 0) {
      return comps.map((comp) => {
        const existingItemFound = initialValue.find((iv) => iv.compId === comp.ID);
        if (existingItemFound) {
          return {
            compId: existingItemFound.compId,
            compName: existingItemFound.compName,
            isSelected: existingItemFound.isSelected,
            isOpen: true,
            selectedOutcomes: existingItemFound.selectedOutcomes,
            selectedOutcomesLimit: comp["Learning Objective"]?.length ?? 0,
          };
        } else {
          return {
            compId: comp.ID,
            compName: comp.Name,
            isSelected: false,
            isOpen: false,
            selectedOutcomes: [],
            selectedOutcomesLimit: comp["Learning Objective"]?.length ?? 0,
          };
        }
      });
    } else {
      return defaultValues;
    }
  }

  function updateEvidenceJson(newData: CompMultiCollapsibleItem[]) {
    if (onValueChanged) {
      const morphedData = newData
        .filter((item) => item.isSelected)
        .map((item) => ({
          compId: item.compId,
          compName: item.compName,
          isSelected: item.isSelected,
          selectedOutcomes: item.selectedOutcomes,
        }));
      onValueChanged(morphedData);
    }
  }

  if (isPDF) {
    return (
      <View>
        <Text style={pdfStyles.reflectionHeader}>{definition["Display Title"] || definition.Name}</Text>
        {Array.isArray(data) &&
          data.map((d, i) => (
            <div key={i}>
              <Text style={pdfStyles.evidenceDate}>
                {d.compName}: {d.selectedOutcomes.map((outcome: any) => outcome.name).join(", ")}
              </Text>
            </div>
          ))}
      </View>
    );
  }

  if (isDisplay) {
    return (
      <div className="readEvidenceBlock">
        <div className="readEvidenceHeader">{definition["Display Title"] || definition.Name}</div>
        {Array.isArray(data) &&
          data.map((d) => (
            <>
              <div className="readEvidenceText">
                {d.compName}: {d.selectedOutcomes.map((outcome: any) => outcome.name).join(", ")}
              </div>
            </>
          ))}
        <div className="h-[1px] w-full bg-grey-30 mt-4" />
      </div>
    );
  }

  return (
    <div>
      {comps.map((comp, index) => {
        const allSelected =
          collapsibleItems[index].selectedOutcomes.length === collapsibleItems[index].selectedOutcomesLimit;

        return (
          <div className="reflectionContentCard" key={comp.ID}>
            <Collapsible
              trigger={
                <IonItem
                  button
                  detail={false}
                  className="evidencePartsCheckboxContainer"
                  onClick={() => {
                    const copy = [...collapsibleItems];
                    copy[index].isOpen = !copy[index].isOpen;
                    setCollapsibleItems(copy);
                    updateEvidenceJson(copy);
                  }}
                >
                  <div className="w-full flex flex-row items-center !justify-between">
                    <div className="text-[17px] font-acc-600 leading-default tracking-[-0.3px] text-grey-90 whitespace-nowrap overflow-hidden text-ellipsis acc-underline decoration-current">
                      {comp.Name}
                    </div>
                    <div className="flex">
                      <img
                        alt=""
                        className="h-icon w-icon img-no-select"
                        src={collapsibleItems[index].isOpen ? Button_Collapse : Button_Expand}
                      />
                      <button className="z-50" onClick={() => {}}>
                        <img
                          alt=""
                          className="h-icon w-icon img-no-select"
                          src={collapsibleItems[index].isSelected ? Button_Checkbox_Active : Button_Checkbox_Inactive}
                        />
                      </button>
                    </div>
                  </div>
                </IonItem>
              }
              triggerDisabled
              transitionTime={250}
              transitionCloseTime={50}
              open={collapsibleItems[index].isOpen}
            >
              <div className="h-[2px] bg-grey-20" />
              <div className="flex justify-between items-center my-2">
                <div className="text-acc-12px font-acc-600 leading-[1.333] text-grey-90">
                  Select the relevant Learning Outcomes for this competency
                </div>
                <button>
                  <div
                    onClick={() => handleToggleAll(index)}
                    className="text-[17px] leading-default tracking-[-0.3] text-cta-blue"
                  >
                    {allSelected ? "Deselect all" : "Select all"}
                  </div>
                </button>
              </div>
              {comp["Learning Objective"]?.map((lo) => {
                const currentLearningOutcomeSelected = collapsibleItems[index].selectedOutcomes.find(
                  (item) => item.id === lo.ID
                );
                return (
                  <IonItem
                    button
                    detail={false}
                    key={lo.ID}
                    className="evidencePartsCheckboxContainer"
                    onClick={() => {
                      const copy = [...collapsibleItems];
                      if (currentLearningOutcomeSelected) {
                        copy[index].selectedOutcomes = copy[index].selectedOutcomes.filter((item) => item.id !== lo.ID);
                      } else {
                        copy[index].isSelected = true;
                        copy[index].selectedOutcomes = [...copy[index].selectedOutcomes, { id: lo.ID, name: lo.Name }];
                      }
                      if (!copy[index].selectedOutcomes.length) {
                        copy[index].isSelected = false;
                      }
                      setCollapsibleItems(copy);
                      updateEvidenceJson(copy);
                    }}
                  >
                    <div className="evidencePartsCheckboxContainerInner">
                      <div className="evidencePartsCheckboxLabel">{lo["Display Name"]}</div>
                      <img
                        alt=""
                        className="evidencePartsRadioCheckbox"
                        src={currentLearningOutcomeSelected ? Button_Checkbox_Active : Button_Checkbox_Inactive}
                      />
                    </div>
                  </IonItem>
                );
              })}
            </Collapsible>
          </div>
        );
      })}
      {/* <pre>{JSON.stringify(collapsibleItems, null, 2)}</pre> */}
    </div>
  );
};

export default CompMultiSelectList;
