import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonImg, IonPage, IonToolbar } from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import { EventRegister } from "react-native-event-listeners";
import { withRouter } from "react-router";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { AppInfoContainer, ContentContainer } from "parafolio-components";
import { FirebaseService } from "../../controllers/FirebaseService";
import DataController from "../../controllers/DataController";
import { accessibilityAtom, appInfoAtom, deviceInfoAtom, userAtom } from "../../state/State";
import { IAccessibilitySettings, IUser } from "../../Interfaces";
import { DeviceInfo } from "@capacitor/device";
import { AppInfo as CapacitorAppInfo } from "@capacitor/app";
import { Button_Audio_Play_White, Button_Audio_Stop } from "../../assets/images";
import { TTSOptions, TextToSpeech } from "@capacitor-community/text-to-speech";
import HeaderTitle from "../../components/common/HeaderTitle";
import { Capacitor } from "@capacitor/core";
import version from "../../environment/version";
import { PF_FAQ_URL } from "../../Constants";
import { useTextToSpeech } from "../../hooks/accessibility/useTextToSpeech";

const AppInfo: React.FC = (props) => {
  const user = useRecoilValueLoadable<IUser | null>(userAtom);
  const deviceInfo = useRecoilValue<DeviceInfo | null>(deviceInfoAtom);
  const appInfo = useRecoilValue<CapacitorAppInfo | null>(appInfoAtom);
  const accessibilitySettings = useRecoilValueLoadable<IAccessibilitySettings | null>(accessibilityAtom);

  const { handleTTS, playing, stopTTS } = useTextToSpeech("appInfoContainer");

  const sendSupportEmail = useCallback((): void => {
    if (user.state === "hasValue" && user.contents) {
      const url = "mailto:apps@class.co.uk";
      const subject = "ParaFolio - General support";

      const body = DataController.supportEmailBody(user.contents.userData, deviceInfo, appInfo);

      window.open(`${url}?subject=${subject}&body=${body}`, "_blank");
    }
  }, [appInfo, deviceInfo, user]);

  useEffect(() => {
    const handleClick = async (event: MouseEvent): Promise<void> => {
      const element = event.target as HTMLAnchorElement;

      if (element.href && element.href?.includes("appInfoContactSupport")) {
        event.preventDefault();
        await FirebaseService.logEvent("support_link_pressed", {
          link: "contact_support",
          type: "app_support",
        });

        sendSupportEmail();
      }
    };

    const handleAuxClick = async (event: MouseEvent): Promise<void> => {
      const element = event.target as HTMLAnchorElement;

      if (event.button === 1) {
        if (element.href && element.href?.includes("appInfoContactSupport")) {
          event.preventDefault();
          await FirebaseService.logEvent("support_link_pressed", {
            link: "contact_support",
            type: "app_support",
          });

          sendSupportEmail();
        }
      }
    };

    document.addEventListener("click", handleClick);
    document.addEventListener("auxclick", handleAuxClick);

    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("auxclick", handleAuxClick);
    };
  }, [sendSupportEmail]);

  useEffect(() => {
    return () => {
      stopTTS();
    };
  }, []);

  useEffect(() => {
    EventRegister.emit("tab-bar/visibility-changed", false);

    return () => EventRegister.emit("tab-bar/visibility-changed", true);
  });

  useEffect(() => {
    const handleClick = async (event: MouseEvent): Promise<void> => {
      const element = event.target as HTMLAnchorElement;

      if (element.href?.includes("mailto:apps@class.co.uk")) {
        event.preventDefault();
        await FirebaseService.logEvent("support_link_pressed", {
          link: "contact_support",
          type: "app_info_feedback",
        });

        sendSupportEmail();
      } else if (element.href?.includes("faqs/#parafolio-app")) {
        await FirebaseService.logEvent("support_link_pressed", {
          link: "faq",
          type: "app_info_feedback",
        });
      } else if (element.href?.includes("tel:")) {
        await FirebaseService.logEvent("support_link_pressed", {
          link: "phone",
          type: "app_info_feedback",
        });
      }
    };

    const handleAuxClick = async (event: MouseEvent): Promise<void> => {
      const element = event.target as HTMLAnchorElement;

      if (event.button === 1) {
        if (element.href?.includes("mailto:apps@class.co.uk")) {
          event.preventDefault();
          await FirebaseService.logEvent("support_link_pressed", {
            link: "contact_support",
            type: "app_info_feedback",
          });

          sendSupportEmail();
        }
      }
    };

    document.addEventListener("click", handleClick);
    document.addEventListener("auxclick", handleAuxClick);

    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("auxclick", handleAuxClick);
    };
  }, [sendSupportEmail]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonBackButton
              className="header-back-buttons"
              defaultHref="/profile"
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <HeaderTitle>{"App Info"}</HeaderTitle>
          {accessibilitySettings.contents?.textToSpeech && (
            <IonButtons slot="end">
              <IonButton className="header-button" mode="ios" onClick={() => handleTTS()}>
                <IonImg src={playing ? Button_Audio_Stop : Button_Audio_Play_White} className="headerIcon" />
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent className="page-background whiteBackground">
        <ContentContainer tabBarDisabled id="appInfoContainer">
          <AppInfoContainer title="Background">
            <p>
              {
                "The ParaFolio app is a new CPD management tool for organising your portfolio for audit. You can add evidence for your CPD activities, upload related documents and write reflections on activities you have undertaken. All your evidence can be easily exported to a PDF to allow you to share your portfolio when audited."
              }
            </p>
          </AppInfoContainer>
          <AppInfoContainer title="Help using the app">
            <p>
              If you have any questions on how to use the app then our FAQ will help to answer some issues:{" "}
              <a href={PF_FAQ_URL} target="_blank" rel="noreferrer">
                https://www.classprofessional.co.uk/faqs/#parafolio-app
              </a>
            </p>
            <p>
              If your question is not covered by the FAQ, or you want to give us feedback or suggestions then please get
              in touch and we’ll try to help:
              <br />
              Email us at{" "}
              <a href="mailto:apps@class.co.uk?subject=ParaFolio - General support" target="_blank" rel="noreferrer">
                apps@class.co.uk
              </a>{" "}
              or call us on{" "}
              <a href="tel:01278427800" target="_blank" rel="noreferrer">
                01278 427 800
              </a>
            </p>
            <p>
              For specific troubleshooting help regarding subscriptions, accounts and signing up/login, please send us{" "}
              <a href="appInfoContactSupport">this formatted email</a> with a description of the issue.
            </p>
            <br />
            <p>
              {Capacitor.getPlatform() === "web" ? (
                <>{`App Version - ${version}`}</>
              ) : (
                <>{`App Version - ${appInfo?.version} (${appInfo?.build})`}</>
              )}
            </p>
          </AppInfoContainer>
        </ContentContainer>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(AppInfo);
