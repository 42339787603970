import React from "react";
import { ICustomProgressCheckEvidenceButton, IProgressCheckEvidenceButton } from "../../types/Components";
import { IonItem } from "@ionic/react";
import * as EvidenceUtils from "../../utils/evidenceUtils";

const CustomProgressCheckEvidenceButton: React.FC<ICustomProgressCheckEvidenceButton> = (props) => {
  const evidenceAdded = EvidenceUtils.checkEvidenceAddedForProgressCheckCompetence(
    props.progressCheckEvidence,
    props.competence,
    props.program.ID
  );

  const showAddedLabel = evidenceAdded && !props.competence.MultipleAllowed;

  const disableItem = props.progressCheckIsLocked || (props.competence.isFinalReview && !props.reviewUnlocked);

  return (
    <IonItem
      button={true}
      className="preceptee-item-button flex flex-row !justify-between items-center"
      detail={false}
      disabled={disableItem}
      onClick={() => props.containerPressed()}
    >
      <div className="min-h-[44px] w-full flex flex-row !justify-between items-center py-[10px]">
        <div>
          <div className="text-[15px] leading-[1.33] tracking-[-0.3px] text-grey-90">{props.competence.Name}</div>
          {props.subtitle && <div className="text-[11px] leading-[1.273] text-grey-60">{props.subtitle}</div>}
        </div>
        <div className={`flex ml-[8px] px-2 py-1`}>
          {showAddedLabel ? (
            <div className="shrink-0 h-[20px] py-0 px-[8px] bg-[#C6F7C1] rounded-[10px] font-inter text-[13px] font-semibold leading-[1.54] tracking-[-0.05px] text-right text-[#085333]">
              {"Added"}
            </div>
          ) : (
            !props.progressCheckIsApproved && (
              <div
                className="font-[15px] leading-[1.33] tracking-[-0.3px] text-cta-blue"
                onClick={(event) => props.addPressed(event)}
              >
                {"Add"}
              </div>
            )
          )}
        </div>
      </div>
    </IonItem>
  );
};

export default CustomProgressCheckEvidenceButton;
