import { IonItem } from "@ionic/react";
import { CompetenceActivityClass, EvidencePart, LearningObjective } from "../../../Interfaces";
import {
  Button_Checkbox_Active,
  Button_Checkbox_Inactive,
  Button_Collapse,
  Button_Expand,
} from "../../../assets/images";
import { useEffect, useState } from "react";
import NOSSelectContainer from "../../common/NOSSelectContainer";
import Collapsible from "react-collapsible";
import { Text, View } from "@react-pdf/renderer";

type Props = {
  definition: EvidencePart;
  onValueChanged: (value: any) => void;
  toggleCollapsible: () => void;
  collapsibleOpen: boolean;
  data?: any;
  isDisplay?: boolean;
  isPDF?: boolean;
  pdfStyles: any;
  learningOutcomes?: LearningObjective[];
  initiallySelectedLearningOutcomes?: LearningObjective[];
};

const LearningOutcomesList: React.FC<Props> = (props) => {
  const [selectedValues, setSelectedValues] = useState<any[]>(props.initiallySelectedLearningOutcomes ?? []);

  const toggleSelectedObjectives = (lo: any) => {
    let copy = [...selectedValues];
    const selectedItem = selectedValues.find((item) => item.ID === lo.ID);
    if (selectedItem) {
      copy = [...copy].filter((item) => item.ID !== lo.ID);
    } else {
      copy.push(lo);
    }

    setSelectedValues(copy.map((item: any) => ({ Name: item.Name, ID: item.ID })));
  };

  useEffect(() => {
    if (props.onValueChanged) {
      props.onValueChanged(selectedValues);
    }
  }, [selectedValues.length]);

  const allSelected = props.learningOutcomes?.length === selectedValues.length;

  if (props.isPDF) {
    return (
      <View>
        <Text style={props.pdfStyles.reflectionHeader}>
          {props.definition["Display Title"] || props.definition.Name}
        </Text>
        <Text style={props.pdfStyles.evidenceDate}>
          {Array.isArray(props.data) ? props.data?.map((item: any) => item.Name).join(", ") : props.data}
        </Text>
      </View>
    );
  }

  if (props.isDisplay) {
    return (
      <div className="readEvidenceBlock">
        {/* <pre>{JSON.stringify(props.data, null, 2)}</pre> */}
        <div className="readEvidenceHeader">{props.definition["Display Title"] || props.definition.Name}</div>
        <div className="readEvidenceText">
          {Array.isArray(props.data) ? props.data?.map((item: any) => item.Name).join(", ") : props.data}
        </div>
        <div className="h-[1px] w-full bg-grey-30 mt-4" />
      </div>
    );
  }

  return (
    <NOSSelectContainer>
      {/* <pre>{JSON.stringify(props.learningOutcomes, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(selectedValues, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(props.definition["Learning Objective"], null, 2)}</pre> */}
      <Collapsible
        trigger={
          <IonItem
            button
            detail={false}
            className="evidencePartsCheckboxContainer"
            onClick={() => props.toggleCollapsible()}
          >
            <div className="w-full flex flex-row items-center !justify-between">
              <div className="text-[17px] font-acc-600 leading-default tracking-[-0.3px] text-grey-90 whitespace-nowrap overflow-hidden text-ellipsis acc-underline decoration-current">
                {props.definition["Display Title"] ?? "Learning Outcomes"}
                {(props.definition.options === "Mandatory" || props.definition["option type"] === "Mandatory") &&
                  " (Mandatory)"}
              </div>
              <button className="z-50" onClick={() => {}}>
                <img
                  alt=""
                  className="h-icon w-icon img-no-select"
                  src={props.collapsibleOpen ? Button_Collapse : Button_Expand}
                />
              </button>
            </div>
          </IonItem>
        }
        triggerDisabled
        transitionTime={250}
        transitionCloseTime={50}
        open={props.collapsibleOpen}
      >
        <div className="h-[2px] bg-grey-20" />
        <div className="flex flex-row items-center justify-between mt-2 mb-1">
          <div className="text-acc-12px font-acc-600 leading-[1.333] text-grey-90">
            {"Select the relevant Learning Outcomes for this competency"}
          </div>
          <button>
            <div
              className="text-[17px] leading-default tracking-[-0.3] text-cta-blue"
              onClick={() =>
                allSelected
                  ? setSelectedValues([])
                  : setSelectedValues(props.learningOutcomes!.map((it) => ({ Name: it.Name, ID: it.ID })))
              }
            >
              {allSelected ? "Deselect all" : "Select all"}
            </div>
          </button>
        </div>
        {props.learningOutcomes?.map((lo) => {
          const selected = selectedValues.find((item) => item.ID === lo.ID);

          return (
            <IonItem
              key={lo.ID}
              button
              detail={false}
              className="evidencePartsCheckboxContainer"
              onClick={() => toggleSelectedObjectives(lo)}
            >
              <div className="w-full flex flex-row items-center !justify-between">
                <div className="my-1 flex-1">
                  <div className="text-[17px] font-acc-400 leading-default tracking-[-0.3px] text-grey-90 overflow-hidden text-ellipsis acc-underline decoration-current">
                    {lo.Name}
                  </div>
                </div>
                <button
                  onClick={(event?: any) => {
                    event?.stopPropagation();
                    toggleSelectedObjectives(lo);
                  }}
                >
                  <img
                    className="h-icon w-icon img-no-select"
                    src={selected ? Button_Checkbox_Active : Button_Checkbox_Inactive}
                  />
                </button>
              </div>
            </IonItem>
          );
        })}
      </Collapsible>
    </NOSSelectContainer>
  );
};

export default LearningOutcomesList;
