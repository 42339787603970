import { App, URLOpenListenerEvent } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import { getPlatforms, isPlatform } from "@ionic/react";
import { useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { EventRegister } from "react-native-event-listeners";
import { FirebaseService } from "../../controllers/FirebaseService";

const DeepLinkingListener: React.FC = () => {
  const history = useHistory();
  const interval = useRef<any>();

  const waitForUser = async (): Promise<void> => {
    return new Promise((resolve) => {
      const active = document.getElementById("deep-linking-active");

      if (active) {
        resolve();
        return;
      }

      interval.current = setInterval(() => {
        const active = document.getElementById("deep-linking-active");

        if (active) {
          clearInterval(interval.current);

          setTimeout(() => {
            resolve();
          }, 500);
        }
      }, 500);
    });
  };

  const handleAppUrlOpen = async (url: string): Promise<void> => {
    let path = url.replace(/#\//g, "");
    path = path.replace(/@/g, "&");

    const parsedURL = new URL(path);

    const addReflection = parsedURL.searchParams.get("addreflection");
    const title = parsedURL.searchParams.get("title");
    const date = parsedURL.searchParams.get("date");
    const activityDate = parsedURL.searchParams.get("activitydate");
    const activityDescription = parsedURL.searchParams.get("activitydescription");
    const timelineReference = parsedURL.searchParams.get("timelinereference");

    const externalURLParam = parsedURL.searchParams.get("externalurl");

    let universalLinkParam = null;

    if (!url.includes("?")) {
      if (path.includes("parafolio.co.uk") || path.includes("paraportfolio.co.uk")) {
        universalLinkParam = parsedURL.pathname;
      } else if (path.includes("parafolio://")) {
        universalLinkParam = url.replace("parafolio://", "");
      }
    }

    await waitForUser();

    if (universalLinkParam) {
      FirebaseService.logEvent("deep_linking", { type: "universal", path: universalLinkParam });

      history.push(universalLinkParam);
    } else if (externalURLParam) {
      FirebaseService.logEvent("deep_linking", { type: "external", path: externalURLParam });

      Browser.open({ url: externalURLParam, presentationStyle: "popover" });
    } else if (addReflection === "true") {
      FirebaseService.logEvent("deep_linking", { type: "add_evidence" });

      EventRegister.emit("evidence-modal/add-evidence-deeplink", {
        ...(title && { title }),
        ...(date && { date: Number(date) }),
        ...(activityDate && { activityDate: Number(activityDate) }),
        ...(activityDescription && { activityDescription }),
        ...(timelineReference && { timelineReference }),
      });
    }
  };

  useEffect(() => {
    if (isPlatform("desktop" || isPlatform("mobileweb"))) {
      let href = window.location.href;
      handleAppUrlOpen(href);
    } else {
      App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
        handleAppUrlOpen(event.url);
      });
    }
  }, []);

  return null;
};

export default DeepLinkingListener;
