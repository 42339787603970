import {
  AllProgressCheckStatuses,
  INOSSelectCompetence,
  INOSSelectLearningObjectives,
  IProgressCheckLabel,
  IUser,
  IUserProgramRoles,
  LearningObjective,
  Mentor,
  ProgramDataVersionInfo,
  ProgressCheckStatus,
  ProgressCheckSubmission,
  ProgressState,
  SequentiallyLocked,
  SubmissionButtonState,
  SubmissionState,
} from "./../Interfaces";
import {
  CompetenceActivityClass,
  ConfidenceLevelData,
  EvidencePart,
  FieldType,
  IClassSubscription,
  IEvidence,
  ProgramData,
  ProgramSkill,
  ProgressCheck,
  ProgressCheckApproval,
} from "../Interfaces";
import roles from "../assets/json/roles.json";
import standards from "../assets/json/standards.json";
import _ from "lodash";
import { format, subYears } from "date-fns";
import { IMandatoryStandard, IOptionalStandard } from "../Interfaces";
import { ProgramModule, PMUsageData } from "pf-support-lib";
import * as EvidenceUtils from "../utils/evidenceUtils";

/**
 * Checks if the Progress Check can be completed when the only piece of required Evidence in the Progress Check is completed
 * @param progressCheckCompetenceID
 * @returns
 */
export function autoCompleteProgressCheck(progressCheckCompetenceID: string): boolean {
  return ["LQZEW7C7CO6RU", "LVQSZILHMB8Q2", "LVQSO3MTY5R7X"].includes(progressCheckCompetenceID);
}

/**
 *
 * @param submissionState - The ProgressCheckSubmission object for the current progress check
 * @returns If the user can edit the evidence added to their progress check based on its submission status
 */
export function canAddOrEditProgressCheckEvidence(submissionState: ProgressCheckSubmission | undefined): boolean {
  return (
    submissionState?.status !== SubmissionState.Approved &&
    submissionState?.status !== SubmissionState.Submitted &&
    submissionState?.status !== SubmissionState.OnHoldReset
  );
}

/**
 *
 * @param approval - The ProgressCheckApproval enum for the current progress check
 * @returns Whether the progress check requires approval
 */
export function canSubmitProgressCheck(approval: ProgressCheckApproval): boolean {
  const approvalRequired = [
    ProgressCheckApproval.ApprovalOO,
    ProgressCheckApproval.Required,
    ProgressCheckApproval.RequiredExtension,
  ];

  return approvalRequired.includes(approval);
}

/**
 *
 * @param selectedLearningObjectives - All the user's selected Learning Objectives
 * @param learningObjectives - All available Learning Objectives
 * @returns
 */
export function checkAllLearningObjectivesSelected(
  selectedLearningObjectives: INOSSelectLearningObjectives[],
  learningObjectives: LearningObjective[]
): boolean {
  if (learningObjectives.length > 0) {
    const ids = selectedLearningObjectives.map((learningObjective) => learningObjective.id);
    const array = learningObjectives.filter((learningObjective) => !ids.includes(learningObjective.ID));

    return array.length === 0;
  }

  return false;
}

/**
 * Utility function to check if the CompetenceActivity of a Program Module is populated
 * @param key - The key to check for
 * @param object - The object to check
 * @returns false if the object is a string, otherwise returns whether the key exists in the object
 */
function checkCompetenceActivityType<T extends object>(key: PropertyKey, object: T | string): key is keyof T {
  if (typeof object === "string") {
    return false;
  }

  return key in object;
}

/**
 *
 * @param progressCheck - The Progress Check to check
 * @param progressChecks - All Progress Checks in the Program Module
 * @param programEvidence - All the user's Evidence for the Program Module
 * @param programProgressCheckData - The user's Progress Check Data for the current Program Module
 * @returns
 */
export function checkIfProgressCheckIsLocked(
  progressCheck: ProgressCheck,
  progressChecks: ProgressCheck[],
  programEvidence: IEvidence[],
  programProgressCheckData: AllProgressCheckStatuses | null
): boolean {
  if (
    progressCheck.SequentiallyLocked !== undefined &&
    [`${SequentiallyLocked.Unlocked}`, `${SequentiallyLocked.UnlockedIgnore}`].includes(
      progressCheck.SequentiallyLocked
    )
  ) {
    return false;
  }

  const progressCheckIndex = progressChecks.findIndex((item) => item.ID === progressCheck.ID);
  const previousProgressCheck = progressCheckIndex > 0 && progressChecks[progressCheckIndex - 1];

  if (!previousProgressCheck) {
    return false;
  }

  if (isProgressCheckApprovedBasedOnCompTrigger(programEvidence, previousProgressCheck["Competence/Activity"])) {
    return false;
  }

  if (previousProgressCheck.Approval === ProgressCheckApproval.NotRequired) {
    const previousProgressCheckEvidence = getEvidenceForProgressCheck(programEvidence, previousProgressCheck.ID);

    const result = !isAllEvidenceAddedForProgressCheck(
      previousProgressCheckEvidence,
      previousProgressCheck["Competence/Activity"]
    );
    return result;
  }

  let previousProgressCheckData;

  if (programProgressCheckData) {
    previousProgressCheckData = programProgressCheckData.pCs.find((item) => item.pCId === previousProgressCheck.ID);
  }

  if (previousProgressCheckData) {
    return previousProgressCheckData.submissions.status !== SubmissionState.Approved;
  }

  return true;
}

/**
 * Checks if the user has a ProgressCheckStatus for the current progress check
 * @param progressCheckData - The current ProgressCheckStatus object to check
 * @param progressCheckID - The ID of the progress check
 * @returns true if the object exists and its pCId is equal to the progressCheckID
 */
export function checkIfSubmissionExists(
  progressCheckData: ProgressCheckStatus | null,
  progressCheckID: string
): boolean {
  if (progressCheckData) {
    return progressCheckData.pCId === progressCheckID;
  }

  return false;
}

/**
 *
 * @param progressCheckCompetenceID - The ID of the current competence
 * @returns true if the ID is included in the list of competences for the confidence level entry
 */
export function competenceIsConfidenceLevel(progressCheckCompetenceID: string): boolean {
  return [
    "LNA36J9UUQHQ0",
    "LNA36J9UUQHQ1",
    "LNA36J9UUQHQ4",
    "LNA36J9UUQHQ1",
    "LNA36J9UUQHQ6",
    "LNA36J9UUQHQ1",
    "LNA37J9UUQHQ1",
  ].includes(progressCheckCompetenceID);
}

/**
 * Checks whether the button to add Program Module evidence should be disabled in the FAB popover
 * @param program
 * @param allEvidence
 * @param user
 * @returns
 */
export function disableFABProgramButton(program: ProgramData, allEvidence: IEvidence[], user: any): boolean {
  if (isPreceptorship(program.ID)) {
    let result = false;

    const progressChecks = program.ProgressChecks ?? [];

    for (let i = 0; i < progressChecks.length; i++) {
      result = isAllEvidenceAddedForProgressCheck(allEvidence, progressChecks[i]["Competence/Activity"]);

      if (!result) {
        break;
      }
    }

    return result;
  } else if (isEPRR(program.ID)) {
    return !isEprrDetailsSet(user);
  } else if (isMTS(program.ID)) {
    return !isMtsDetailsSet(user);
  }

  return false;
}

/**
 * Checks whether to display the Progress Checks page in the PDF Export
 *
 * SWAST Preceptorship NQP1
 * @param programID - The ID of the current Program Module
 * @returns
 */
export function displayProgressChecksInPDF(programID: string = ""): boolean {
  return ["LNA4FTCKB012M"].includes(programID);
}

/**
 * Checks whether to display the NOS Standards page in the PDF Export - NWAS EPRR
 *
 * NWAS EPRR Evidence
 * @param programID - The ID of the current Program Module
 * @returns
 */
export function displayStandardsInPDF(programID: string = ""): boolean {
  return ["LR0HYZYWY6JQK"].includes(programID);
}

/**
 * Filters the input list of programIDs based on if they include progress checks
 * @param programIDs
 * @returns a filtered array of programIDs
 */
export function filterProgressCheckProgramIDs(programIDs: string[]): string[] {
  const progressCheckProgramIDs = ["LNA4FTCKB012M", "LNA4FTCKB003M"];
  return programIDs.filter((item) => progressCheckProgramIDs.includes(item));
}

/**
 *  Formats the approval text using the name of the mentor who approved the progress check and the time they approved it
 * @param input - The text to format
 * @param submissionStatus - The ProgressCheckSubmission object
 * @returns The formatted string - e.g. "Test User approved this progress check for you at 16:39 on 15 Mar 2024." | An empty string if no input or submissionStatus is present
 */
export function formatApprovalText(
  input: string | undefined,
  submissionStatus: ProgressCheckSubmission | undefined
): string {
  let result = "";

  if (input && submissionStatus) {
    const mentorInfo = submissionStatus.history[0];
    const mentorName = mentorInfo.displayName;
    const date = new Date(mentorInfo.date);

    result = input.replace("[MENTOR]", `<b>${mentorName}</b>`);
    result = result.replace("[DATE]", `<b>${format(date, "H:mm")} on ${format(date, "d MMM yyyy")}</b>`);
  }

  return result;
}

/**
 * Checks whether the currently selected program requires user setup
 * @param programID - The current program ID
 * @returns
 */
export function hasSetup(programID: string): boolean {
  return ["LNLT7JSYDT5PJ", "LR0HYZYWY6JQK", "LNA4FTCKB003M"].includes(programID);
}

export function hasProgressChecks(programData: ProgramData): boolean {
  return !!programData.ProgressChecks;
}

export function canHandleMultiplePiecesOfEvidencePerEvidenceType(programID: string): boolean {
  return ["LNA4FTCKB003M", "LNA4FTCKB005M"].includes(programID);
}

/**
 * Retrieves all the required skills from the current program list of competences
 * @param allCompetences - All competences for the program
 *
 */
export function getAllRequiredSkillsForProgram(allCompetences: CompetenceActivityClass[]): ProgramSkill[] {
  let skills = allCompetences.map((item) => item.Skills);
  skills = skills.filter((skill) => skill !== undefined);
  const flattenedSkills = _.flatten(skills);

  const required = flattenedSkills.filter(
    (skill: any): skill is ProgramSkill =>
      skill && skill.numberRequiredInDuration && parseInt(skill.numberRequiredInDuration, 10) > 0
  );

  return required;
}

/**
 * Calculates the user's NOS compliance
 * @param standards - A concatenated array of the Mandatory Standards for the user's role and their selected Optional Standards
 * @param programsData - The Program Module data
 * @param user - The current user object
 * @param programEvidence - The Program Module evidence
 * @returns The compliance percentage
 */
export function getCompliantCountForStandards(
  standards: IOptionalStandard[] | IMandatoryStandard[],
  programsData: any,
  user: IUser | null,
  programEvidence: IEvidence[]
) {
  if (!user || !programEvidence) return 0;

  let count = 0;
  standards.forEach((standard) => {
    const nwasIC: any = programsData.find((program: any) => program.ID === "LR0HYZYWY6JQK");
    const pm = new ProgramModule(nwasIC);
    const contactID = Number(user.userData.contactID);
    const roleID = getSelectedRoleIdForNWASICProgram(user);
    const pMUsageData = new PMUsageData(nwasIC, []);
    const filteredEvidence = programEvidence
      .filter((ev) => !ev.draft)
      .filter((ev) => new Date(ev.date) >= subYears(new Date(), 2)) as [];
    const results = pMUsageData.getUsageDataByUser(pm, contactID, roleID, filteredEvidence);
    const resultForCurrentStandard = results.find((result: any) => result.compId === standard.id);

    if (resultForCurrentStandard) {
      if (Math.floor(Number(resultForCurrentStandard.percentage)) >= 90) {
        count += 1;
      }
    }
  });

  return count;
}

/**
 * @param evidence - All of the user's evidence
 * @param field - The key inside the evidenceJSON object that contains the graph label value
 * @returns
 */
export function getConfidenceGraphLabel(evidence: IEvidence[], field: string): string | undefined {
  const evidenceWithConfidenceLevels = evidence.find((evidence: IEvidence) => {
    const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
    const progressCheckCompetenceID = evidenceJSON?.programInfo?.progressCheckCompetenceID;

    return progressCheckCompetenceID === "LNA36J9UUQHQ0";
  });

  if (!evidenceWithConfidenceLevels) {
    return undefined;
  }
  const evidenceJSON = JSON.parse(evidenceWithConfidenceLevels.evidenceJSON);

  return evidenceJSON[field];
}

/**
 * Retrieves all the evidence required to create the confidence level graph
 * @param programEvidence - All the evidence for the current program
 * @returns
 */
export function getConfidenceLevelEvidence(programEvidence: IEvidence[]) {
  let evidenceWithConfidenceLevels: IEvidence[] = [];

  evidenceWithConfidenceLevels = programEvidence.filter((evidence: IEvidence) => {
    const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
    const progressCheckCompetenceID = evidenceJSON?.programInfo?.progressCheckCompetenceID;

    return competenceIsConfidenceLevel(progressCheckCompetenceID);
  });

  return evidenceWithConfidenceLevels;
}

/**
 * @param id - The ID of the progress check
 * @returns The index to insert the confidence level data into
 */
export function getConfidenceLevelProgressCheckIndex(id: string): number {
  switch (id) {
    case "LNA4GMRKB031M":
      return 0;
    case "LNA4GMRKB041M":
      return 1;
    case "LNA4GMRKB051M":
      return 2;
    case "LNA4GMRKB081M":
      return 3;
    default:
      return -1;
  }
}

/**
 * Gets the user's selected area (region)
 * @param programRoles - The user's Program Roles array
 * @returns The area if it exists, otherwise returns an empty string
 */
export function getEprrArea(programRoles: IUserProgramRoles[]) {
  if (!programRoles || programRoles.length === 0) return "";

  const eprrProgram = programRoles.find((program) => program.programID === "LR0HYZYWY6JQK");
  return eprrProgram ? eprrProgram.area : "";
}

/**
 * Gets the key of the evidence part that contains the activity type
 * @param evidence - The current piece of evidence
 * @returns The evidence parts key that contains the activity type
 */
export function getEvidenceActivityTypeKey(evidence: IEvidence): string | undefined {
  let evidenceJSON;

  if (typeof evidence.evidenceJSON === "string") {
    JSON.parse(evidence.evidenceJSON);
  } else if (typeof evidence.evidenceJSON === "object") {
    evidenceJSON = evidence.evidenceJSON;
  }

  if (evidenceJSON) {
    switch (evidenceJSON.programInfo.program) {
      case "SWAST HART and EPRR Instructors":
        return "CPD Activity - HART and EPRR";
      default:
        return;
    }
  }

  return;
}

/**
 * Gets the key of the attachments upload evidence part from an array of evidence parts
 * @param evidenceParts - The evidence parts to check
 * @returns The key of the attachments upload evidence part
 */
export function getEvidenceAttachmentsKey(evidenceParts: EvidencePart[]): string | undefined {
  const attachmentsPart = evidenceParts.find((item) => item["Field type"] === FieldType.Attachments);

  if (attachmentsPart) {
    const key = attachmentsPart.Name;

    return key;
  }

  return;
}

/**
 * @param programEvidence - The evidence for the current program
 * @param progressCheckID  - The ID of the progress check
 * @returns The number of pieces of evidence the user has created for the current progress check
 */
export function getEvidenceCountForProgressCheck(programEvidence: IEvidence[], progressCheckID: string): number {
  const progressCheckEvidence = programEvidence.filter((evidence) => {
    const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
    const id = evidenceJSON?.programInfo?.progressCheckID || "";

    const onHoldReset = evidenceJSON.OnHoldReset;

    const value = id === progressCheckID && onHoldReset !== 1 && evidence.draft !== true;

    return value;
  });

  return progressCheckEvidence.length;
}

/**
 * Gets the key of the evidence part that contains the custom tags
 * @param evidence - The current piece of evidence
 * @returns The evidence parts key that contains the custom tags
 */
export function getEvidenceCustomTagsKey(evidence: IEvidence): string | undefined {
  let evidenceJSON;

  if (typeof evidence.evidenceJSON === "string") {
    JSON.parse(evidence.evidenceJSON);
  } else if (typeof evidence.evidenceJSON === "object") {
    evidenceJSON = evidence.evidenceJSON;
  }

  if (evidenceJSON) {
    switch (evidenceJSON.programInfo.program) {
      case "SWAST HART and EPRR Instructors":
        return "Custom tags";
      default:
        return;
    }
  }

  return;
}

/**
 * @param submissionState - The ProgressCheckSubmission object for the current progress check
 * @returns The correct disclaimer text if the submission status is Submitted or Approved, otherwise an empty string
 */
export function getEvidenceDisclaimerText(submissionState: ProgressCheckSubmission | undefined): string {
  switch (submissionState?.status) {
    case SubmissionState.Submitted:
      return "You can no longer add or edit evidence to this progress check now you have submitted it to your Preceptor.";
    case SubmissionState.Approved:
      return "You can no longer add new evidence or edit existing evidence because your progress check is approved.";
    default:
      return "";
  }
}

/**
 * Retrieves all of the user's evidence for the current program
 * @param allEvidence - All of the current user's evidence
 * @param program - The current program name
 * @returns The filtered evidence for the program
 */
export function getEvidenceForProgramName(allEvidence: IEvidence[], programName: string) {
  let evidenceForProgram: IEvidence[] = [];

  evidenceForProgram = allEvidence?.filter((evidence: IEvidence) => {
    const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
    const program = evidenceJSON?.programInfo?.program || "";

    return program === programName;
  });

  evidenceForProgram = _.orderBy(evidenceForProgram, "date", "desc");

  return evidenceForProgram;
}

/**
 * Retrieves all of the user's Evidence for the current Program Module
 * @param allEvidence - All of the current user's Evidence
 * @param program - The current Program Module's name
 * @returns The filtered Evidence for the Program Module
 */
export function getEvidenceForProgramID(allEvidence: IEvidence[], programID: string) {
  let evidenceForProgram: IEvidence[] = [];

  evidenceForProgram = allEvidence?.filter((evidence: IEvidence) => {
    const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
    const id = evidenceJSON?.programInfo?.programID || "";

    return id === programID;
  });

  evidenceForProgram = _.orderBy(evidenceForProgram, "date", "desc");

  return evidenceForProgram;
}

/**
 * Retrieves all of the user's Evidence for the current Progress Check
 * @param programEvidence - All of the user's Evidence for the current Progress Check
 * @param progressCheckID - The current Progress Check ID
 * @returns The filtered Evidence for the Progress Check
 */
export function getEvidenceForProgressCheck(programEvidence: IEvidence[], progressCheckID: string): IEvidence[] {
  let result: IEvidence[] = [];

  result = programEvidence?.filter((evidence: IEvidence) => {
    const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
    const id = evidenceJSON?.programInfo?.progressCheckID || "";

    const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

    return id === progressCheckID && onHoldReset !== 1 && evidence.draft !== true;
  });

  return result;
}

/**
 * @param evidencePart - The Evidence Part to get the value for
 * @param evidenceJSON
 * @param evidence - All of the user's Evidence
 * @param progressCheckCompetence - The current Progress Check Competence if needed (for ConfidenceLevelGraph)
 * @returns The initial value for the Evidence Part
 */
export function getEvidencePartInitialValue(
  evidencePart: EvidencePart,
  evidenceJSON: any,
  evidence: IEvidence[],
  progressCheckCompetence?: CompetenceActivityClass | null
): any {
  if (evidencePart["Field type"] === FieldType.NOSSelect) {
    return {
      Comps: evidenceJSON.Comps ?? [],
      LOs: evidenceJSON.LOs ?? [],
    };
  } else if (evidencePart["Field type"] === FieldType.CompMultiSelect) {
    return evidenceJSON["Comp Selector"];
  } else if (isFieldLocked(progressCheckCompetence?.ID, evidencePart.ID)) {
    return getConfidenceGraphLabel(evidence, evidencePart.Name);
  } else {
    return evidenceJSON[evidencePart.Name];
  }
}

/**
 *
 * @param mandatoryStandards - All of the Mandatory Standards for the user's chosen Role
 * @param optionalStandards - The Optional Standards that the user has selected
 * @param selectedStandard - The Standard that the user has selected in the Evidence Modal
 * @param learningObjectives - All of the Learning Objective for the Program Module
 * @returns The user's selected Learning Objectives that correspond to the selected Standard
 */
export function getLearningObjectivesForStandard(
  mandatoryStandards: CompetenceActivityClass[] | undefined,
  optionalStandards: CompetenceActivityClass[] | undefined,
  selectedStandard: INOSSelectCompetence,
  learningObjectives: INOSSelectLearningObjectives[]
): INOSSelectLearningObjectives[] | undefined {
  const standards = mandatoryStandards?.concat(optionalStandards ?? []) ?? [];
  const standard = standards?.find((item) => item.ID === selectedStandard.id);
  const mappedLearningObjectives = standard?.["Learning Objective"]?.map((item) => item.ID) ?? [];

  const array = learningObjectives.filter((item) => mappedLearningObjectives.includes(item.id));

  return array ?? undefined;
}

/**
 *
 * @param user - Current user object
 * @param program - The current Program Module
 * @returns An array of the Mandatory Standards for the user's selected role if selected, otherwise an empty array
 */
export function getMandatoryStandards(user: IUser, program: ProgramData): CompetenceActivityClass[] {
  if (user && program) {
    const nwasICProgram = user.programRoles?.find((role) => role.programID === "LR0HYZYWY6JQK");
    const selectedRoleID = nwasICProgram?.programRoles[0].roleID;
    const role = program.Roles?.find((item) => item.ID === selectedRoleID);

    if (role?.CompetenceActivity && checkCompetenceActivityType("Name", role.CompetenceActivity[0])) {
      const array: CompetenceActivityClass[] = role.CompetenceActivity as CompetenceActivityClass[];
      return array ?? [];
    }
  }

  return [];
}

/**
 * Retrieves the mandatory standards for a selected role
 * @param {any} program - the whole program json
 * @param {string} roleId - role id to obtain standards for
 * @returns An array of mandatory standards
 */
export function getMandatoryStandardsFromProgram(program: any, roleId: string): IMandatoryStandard[] {
  if (!roleId) {
    return [];
  }

  const mandatoryStandards: IMandatoryStandard[] = Array.from(
    program.Roles.find((r: any) => r.ID === roleId).CompetenceActivity
  ).map((item: any) => {
    const learningObjectives: LearningObjective[] = item["Learning Objective"].map((item: any) => item);
    return { id: item.ID, name: item.Name, description: item.Description, learningObjectives: learningObjectives };
  });

  return mandatoryStandards;
}

/**
 * Returns the standard tags for the user's SWAST IC role
 * @param role - The current user's SWAST IC role
 * @returns The mapped array of mandatory and optional standard tags
 */
export function getNOSStandardTagsForRole(role: string) {
  const roleObject = roles.find((item) => item.Name === role);

  const optional = roleObject?.OptionalRoles;
  const mandatory = roleObject?.MandatoryRoles;

  const mandatoryRoles = mandatory?.map((id) => standards.find((item) => item.id === id));
  const optionalRoles = optional?.map((id) => standards.find((item) => item.id === id));

  return {
    mandatory: _.orderBy(mandatoryRoles, ["Code"], ["asc"]) || [],
    optional: _.orderBy(optionalRoles, ["Code"], ["asc"]) || [],
  };
}

/**
 *
 * @param user - Current user object
 * @param program - The current Program Module
 * @returns An array of the Optional Standards for the user's selected role if selected, otherwise an empty array
 */
export function getOptionalStandards(user: IUser, program: ProgramData): CompetenceActivityClass[] {
  if (user && program) {
    const nwasICProgram = user.programRoles?.find((role) => role.programID === "LR0HYZYWY6JQK");
    const selectedRoleID = nwasICProgram?.programRoles[0].roleID;

    const optionalStandards = nwasICProgram?.programRoles[0].optionalStandards ?? [];
    const optionalStandardsIDs = optionalStandards.map((item: any) => item.id);

    const role = program.Roles?.find((item) => item.ID === selectedRoleID);

    if (role?.OptionalCompetenceActivity && checkCompetenceActivityType("Name", role.OptionalCompetenceActivity[0])) {
      const array: CompetenceActivityClass[] = role.OptionalCompetenceActivity as CompetenceActivityClass[];

      const selectedStandards = array.filter((item) => optionalStandardsIDs.includes(item.ID));

      return selectedStandards ?? [];
    }
  }

  return [];
}

/**
 * Retrieves the optional standards selected by a user
 * @param {any} user - user object
 * @returns An array of mandatory standards
 */
export function getOptionalStandardsFromUser(user: IUser | null, program: any) {
  if (!user) return [];

  const nwasICProgram = user.programRoles?.find((role) => role.programID === "LR0HYZYWY6JQK");

  if (!nwasICProgram) return [];

  const optionalStandards: IOptionalStandard[] = Array.from(nwasICProgram?.programRoles[0].optionalStandards).map(
    (standard: any) => {
      let description = "";
      let learningObjectives: LearningObjective[] = [];
      const role = program.Roles.find((role: any) => role.ID === nwasICProgram?.programRoles[0].roleID);
      const matchingCompetence = role.OptionalCompetenceActivity.find((comp: any) => comp.ID === standard.id);
      if (matchingCompetence) {
        description = matchingCompetence.Description;
        learningObjectives = matchingCompetence["Learning Objective"];
      }
      return {
        id: standard.id,
        name: standard.name,
        learningObjectives: learningObjectives,
        description: description,
      };
    }
  );

  return optionalStandards;
}

/**
 * Finds the user's ParaFolio subscriptions and maps any ParaFolioPrograms fields withing them to the corresponding programID
 * @param subscriptions - All of the users Class subscriptions, not only ParaFolio subscriptions
 * @param programs - The full list of programs
 * @returns An array of ProgramIDs
 */
export function getProgramIDsFromSubscriptions(
  subscriptions: IClassSubscription[] | null,
  programs: ProgramData | any
): string[] {
  if (subscriptions) {
    const programSubscriptions: IClassSubscription[] = subscriptions.filter(
      (item: IClassSubscription) => item?.ParaFolioPrograms && item?.ParaFolioPrograms.length > 0
    );

    const subscriptionNameArrays = programSubscriptions.map((item) => item.ParaFolioPrograms);
    const uniqueProgramName = _.uniq(_.flatten(subscriptionNameArrays));

    let programIDs = uniqueProgramName.map((item) => {
      const program = programs.find((_item: any): _item is ProgramData => {
        return _item.Subscription?.includes(item) ?? undefined;
      });

      return program?.ID ?? undefined;
    });

    programIDs = programIDs.filter((item) => item !== undefined);

    return programIDs;
  }

  return [];
}

/**
 *
 * @param programName - The current Program Module's name
 * @returns The CSS class name for the ProgramCard background style
 */
export function getProgramCardBackground(programName: string): string {
  return "programCardBackground" + programName.replace(/\s/gim, "");
}

/**
 * Returns the progress check for the current piece of evidence based on the progressCheckID
 * @param evidenceJSON
 * @param program
 * @returns The progress check if it exists or null if it doesn't
 */
export function getProgressCheckForEvidence(evidenceJSON: any, program: ProgramData | undefined): ProgressCheck | null {
  if (program) {
    const progressCheckID = evidenceJSON?.programInfo?.progressCheckID;
    const progressChecks = program.ProgressChecks || [];

    const progressCheck = progressChecks.find((item: ProgressCheck) => item.ID === progressCheckID);

    return progressCheck ? progressCheck : null;
  }

  return null;
}

/**
 *
 * @param progressCheckID
 * @returns The correct info string whether the previous Progress Check required approval or not
 */
export function getProgressCheckLockedDisclaimerText(progressCheckID: string): string {
  switch (progressCheckID) {
    case "LNA4GMRKB071M":
    case "LNA4GMRKB081M":
      return "You cannot add evidence to this progress check until you have completed the previous progress check.";
    default:
      return "You cannot add evidence to this progress check until your previous progress check has been approved.";
  }
}

/**
 *
 * @param progressCheckID - The ID of the Progress Check to find
 * @param progressCheckData - The array of all Progress Check Status data
 * @param program
 * @returns The Progress Check Status if it exists or null if it doesn't
 */
export function getProgressCheckStatusFromEvidence(
  progressCheckID: string,
  progressCheckData: AllProgressCheckStatuses[] | null,
  program: ProgramData | undefined
): ProgressCheckStatus | null {
  if (program && progressCheckData) {
    const allProgressCheckStatuses = progressCheckData.find((item) => item.programID === program.ID);
    const progressCheckStatus = allProgressCheckStatuses?.pCs.find((item) => item.pCId === progressCheckID);

    return progressCheckStatus ?? null;
  }

  return null;
}

/**
 * Gets the correct information for the ProgressCheckButton label
 * @param progressCheck - The Progress Check to process
 * @param progressCheckStatus - The status object of the Progress Check
 * @param programEvidence - The Evidence for the Progress Check
 * @param progressChecks - All of the Progress Checks
 * @param allProgressCheckData - The status objects for all the Progress Checks
 * @returns an object with a label and status. e.g. { label: "Approved", status:"Completed" }
 */
export function getProgressCheckLabelInformationForMTS(
  currentProgressCheck: ProgressCheck,
  programEvidence: IEvidence[],
  previousProgressCheck?: ProgressCheck
) {
  const evidenceForProgressCheck = programEvidence.filter((e) => {
    const parsedEvidence = JSON.parse(e.evidenceJSON);
    if (!e.draft && parsedEvidence.programInfo.progressCheckID === currentProgressCheck.ID) {
      return true;
    }
  });

  let previousProgressCheckIsIncomplete = false;
  if (previousProgressCheck) {
    const evidenceForPreviousProgressCheck = programEvidence.filter((e) => {
      const parsedEvidence = JSON.parse(e.evidenceJSON);
      const onHoldReset = parsedEvidence?.OnHoldReset ?? 0;
      if (!e.draft && onHoldReset === 0 && parsedEvidence.programInfo.progressCheckID === previousProgressCheck.ID) {
        return true;
      }
    });
    const previousProgressCheckIsApproved = isProgressCheckApprovedBasedOnCompTrigger(
      evidenceForPreviousProgressCheck,
      previousProgressCheck["Competence/Activity"]
    );
    previousProgressCheckIsIncomplete = !previousProgressCheckIsApproved;
  }

  const approved = isProgressCheckApprovedBasedOnCompTrigger(
    evidenceForProgressCheck,
    currentProgressCheck["Competence/Activity"]
  );

  const onHoldResetExists = evidenceForProgressCheck.find((e) => {
    const parsed = JSON.parse(e.evidenceJSON);
    if (parsed && parsed.OnHoldReset && parsed.OnHoldReset == 1) {
      return true;
    }
  });

  if (approved) {
    return {
      label: "Approved",
      status: "Completed",
    };
  } else if (!approved && onHoldResetExists) {
    return {
      label: "Reset",
      status: "Reset",
    };
  } else if (evidenceForProgressCheck.length > 0) {
    return {
      label: "In progress",
      status: "In progress",
    };
  } else if (previousProgressCheckIsIncomplete) {
    return {
      label: "Locked",
      status: "Locked",
    };
  } else {
    return {
      label: "Not started",
      status: "Not started",
    };
  }
}

export function getProgressCheckLabelInformation(
  progressCheck: ProgressCheck,
  progressCheckStatus: ProgressCheckStatus | undefined,
  programEvidence: IEvidence[],
  progressChecks: ProgressCheck[],
  allProgressCheckData: AllProgressCheckStatuses | null
): IProgressCheckLabel | undefined {
  if (progressCheckStatus?.submissions.status === SubmissionState.Approved) {
    return {
      label: SubmissionState.Approved,
      status: "Completed",
    };
  }

  if (checkIfProgressCheckIsLocked(progressCheck, progressChecks, programEvidence, allProgressCheckData)) {
    return {
      label: "Locked",
      status: "Not started",
    };
  }

  const progressCheckEvidence = getEvidenceForProgressCheck(programEvidence, progressCheck.ID);

  if (progressCheckEvidence.length > 0) {
    const completed = isAllEvidenceAddedForProgressCheck(progressCheckEvidence, progressCheck["Competence/Activity"]);

    if (completed) {
      if (progressCheck.Approval === ProgressCheckApproval.NotRequired) {
        return {
          label: "Completed",
          status: "Completed",
        };
      } else if (progressCheckStatus?.submissions.status === SubmissionState.Submitted) {
        return {
          label: ProgressState.Submitted,
          status: ProgressState.InProgress,
        };
      } else if (progressCheckStatus?.submissions.status === SubmissionState.OnHold) {
        return {
          label: "Submission held",
          status: "Attention",
        };
      } else if (progressCheckStatus?.submissions.status === SubmissionState.OnHoldReset) {
        return {
          label: "Submission held",
          status: "Attention",
        };
      } else if (showProgressCheckEvidenceCount(progressCheck.ID)) {
        return {
          label: `${progressCheckEvidence.length}/${progressCheck["Competence/Activity"].length}`,
          status: "Completed",
        };
      } else {
        return {
          label: "Ready for submission",
          status: ProgressState.InProgress,
        };
      }
    } else if (showProgressCheckEvidenceCount(progressCheck.ID)) {
      return {
        label: `${progressCheckEvidence.length}/${progressCheck["Competence/Activity"].length}`,
        status: "In progress",
      };
    } else {
      return {
        label: "In progress",
        status: ProgressState.InProgress,
      };
    }
  }

  if (
    progressCheck.SequentiallyLocked === SequentiallyLocked.Unlocked ||
    progressCheck.SequentiallyLocked === SequentiallyLocked.UnlockedIgnore
  ) {
    return {
      label: "Open",
      status: "Open",
    };
  }

  return {
    label: "Not started",
    status: "Not started",
  };
}

/**
 * @param submissionState - The current ProgressCheckSubmission object
 * @returns The CSS class for the submission state button
 */
export function getProgressCheckSubmissionButtonClass(
  submissionState: ProgressCheckSubmission | undefined
): string | undefined {
  if (submissionState) {
    if (submissionState.status === SubmissionState.Submitted) {
      return "!program-card-button-submitted";
    }

    if (submissionState.status === SubmissionState.OnHold) {
      return "!program-card-button-cta";
    }

    if (submissionState.status === SubmissionState.OnHoldReset) {
      return "!program-card-button-cta";
    }

    if (submissionState.status === SubmissionState.Approved) {
      return "!program-card-button-approved";
    }
  }

  return undefined;
}

/**
 * Gets the submission button text for the current progress check submission state
 * @param submissionState
 * @returns Submitted | Waiting for approval | Resubmit
 */
export function getProgressCheckSubmissionButtonText(submissionState: ProgressCheckSubmission | undefined): string {
  if (submissionState) {
    if (submissionState.status === SubmissionState.Submitted) {
      return SubmissionButtonState.WaitingForApproval;
    }

    if (submissionState.status === SubmissionState.OnHold) {
      return SubmissionButtonState.Resubmit;
    }

    if (submissionState.status === SubmissionState.OnHoldReset) {
      return SubmissionButtonState.Resubmit;
    }

    if (submissionState.status === SubmissionState.Approved) {
      return SubmissionButtonState.Approved;
    }
  }

  return SubmissionButtonState.Submit;
}

/**
 * @param submissionState - The current ProgressCheckSubmission object
 * @returns The CSS class for the submission state button text
 */
export function getProgressCheckSubmissionButtonTextClass(
  submissionState: ProgressCheckSubmission | undefined
): string | undefined {
  if (submissionState) {
    if (submissionState.status === SubmissionState.Submitted) {
      return "!text-orange-text";
    }

    if (submissionState.status === SubmissionState.OnHold) {
      return "!text-white";
    }

    if (submissionState.status === SubmissionState.OnHoldReset) {
      return "!text-white";
    }

    if (submissionState.status === SubmissionState.Approved) {
      return "!text-green-text";
    }
  }

  return undefined;
}

/**
 * @param status - The current ProgressCheckSubmission state
 * @returns The Tailwind extended colour class name
 */
export function getProgressStatusBackgroundColor(status: string): string {
  if (status === ProgressState.NotStarted) {
    return "grey-30";
  }

  if (status === ProgressState.InProgress) {
    return "orange-20";
  }

  if (status === ProgressState.Submitted) {
    return "orange-20";
  }

  if (status === ProgressState.ReadyForSubmission) {
    return "orange-20";
  }

  return "grey-30";
}

/**
 * @param status - The current ProgressCheckSubmission state
 * @returns The Tailwind extended colour class name
 * @returns
 */
export function getProgressStatusTextColor(status: string): string {
  if (status === ProgressState.NotStarted) {
    return "text-grey-60";
  }

  if (status === ProgressState.InProgress) {
    return "text-orange-text";
  }

  if (status === ProgressState.Submitted) {
    return "text-orange-text";
  }

  if (status === ProgressState.ReadyForSubmission) {
    return "text-orange-text";
  }

  return "text-grey-60";
}

/**
 * Returns roles label for the program details component
 * @param programID - The current program ID
 * @returns
 */
export function getRolesLabel(programID: string): string {
  const programsWithMandatoryLabel = ["LR0HYZYWY6JQK"];
  if (programsWithMandatoryLabel.includes(programID)) {
    return "Your role (Mandatory)";
  }
  return "Your roles";
}

/**
 *
 * @param user - The current user object
 * @returns The selected role id if the user exists, otherwise returns an empty string
 */
export function getSelectedRoleIdForNWASICProgram(user: IUser | null) {
  if (!user) return "";

  const nwasICProgram = user.programRoles?.find((role) => role.programID === "LR0HYZYWY6JQK");
  const selectedRoleID = nwasICProgram?.programRoles[0].roleID;

  return selectedRoleID;
}

/**
 * Checks whether the currently selected program requires user setup
 * @param programID - The current program ID
 * @returns
 */

/**
 * Checks whether the expiry date for a certificate input should be hidden.
 * @param programID - The current program ID
 * @returns
 */
export function hideExpiryDateCertInput(programID: string): boolean {
  return ["LR0HYZYWY6JQK"].includes(programID);
}

/**
 * Checks whether the support line should be hidden in the info modal
 * @param programID - The current program ID
 * @returns
 */
export function hideHardcodedSupportLine(programID: string): boolean {
  return ["LR0HYZYWY6JQK", "LNA4FTCKB003M"].includes(programID);
}

/**
 * Checks whether the program card should show standards and evidence details (used by EPRR)
 * @param programID - The current program ID
 * @returns
 */
export function isEPRR(programID: string): boolean {
  return ["LR0HYZYWY6JQK"].includes(programID);
}

/**
 * Checks whether the user details are set for the NWAS EPRR Program Module
 * @param user - The current user object
 * @returns
 */
export function isEprrDetailsSet(user: any) {
  if (user && user.programRoles) {
    const eprrProgram = user.programRoles.find((program: any) => program.programID === "LR0HYZYWY6JQK");
    return eprrProgram ? true : false;
  }

  return false;
}

export function isMtsDetailsSet(user: any) {
  if (user && user.programRoles) {
    const mtsProgram = user.programRoles.find((program: any) => program.programID === "LNA4FTCKB003M");
    return mtsProgram ? true : false;
  }

  return false;
}

/**
 * Checks whether the details card should be displayed
 * @param programID - The current program ID
 * @returns
 */
export function isPreceptorship(programID: string): boolean {
  return ["LNA4FTCKB012M"].includes(programID);
}

/**
 * Checks whether the chosen Progress Check is finished (Approved or Completed)
 * @param submissionState - The submission history for the chosen Progress Check
 * @returns
 */
export function isProgressCheckFinished(submissionState: ProgressCheckSubmission | undefined): boolean {
  return submissionState?.status === SubmissionState.Approved || submissionState?.status === SubmissionState.Completed;
}

/**
 * @param progressCheckEvidence - The Evidence for the current Progress Check
 * @param competences - All of the Progress Checks in the Program Module
 * @returns Whether all of the Evidence is added for the Progress Check
 */
export function isAllEvidenceAddedForProgressCheck(
  progressCheckEvidence: IEvidence[],
  competences: CompetenceActivityClass[]
): boolean {
  let evidenceForProgressCheck: IEvidence[] = _.cloneDeep(progressCheckEvidence) || [];

  evidenceForProgressCheck = evidenceForProgressCheck.filter((item) => {
    const evidenceJSON = item.evidenceJSON && JSON.parse(item.evidenceJSON);

    const onHoldReset = evidenceJSON?.OnHoldReset === 1;

    return !onHoldReset && item.draft !== true;
  });

  const allCompetences = _.cloneDeep(competences);

  const progressCheckIDs = evidenceForProgressCheck
    .map((item: IEvidence) => {
      const evidenceJSON = item.evidenceJSON && JSON.parse(item.evidenceJSON);
      return evidenceJSON?.programInfo?.progressCheckCompetenceID;
    })
    .sort();

  const competenceIDs = allCompetences.map((item) => item.ID).sort();

  return competenceIDs.every((id) => progressCheckIDs.includes(id));
}

/**
 *
 * @param allEvidenceAdded
 * @param approval
 * @returns true if all Evidence is added for the Progress Check and it doesn't require approval
 */
export function isProgressCheckCompleted(allEvidenceAdded: boolean, approval: string): boolean {
  return allEvidenceAdded && approval === ProgressCheckApproval.NotRequired;
}

// Checks if a progress check is completed based on it's evidence. This function should be used for program modules that are approved
// based on a competence trigger. For example, the data in the "End of Course Review" competence in MTS Telephone Triage acts as a trigger for approval.
export function isProgressCheckApprovedBasedOnCompTrigger(
  evidence: IEvidence[],
  progressCheckComps: CompetenceActivityClass[]
): boolean {
  if (!evidence) return false; // check for falsy evidence value because app can get into weird state where this undefined

  let result = false;
  const compUsedForApproval = progressCheckComps.find((comp) => comp.isFinalReview);
  if (compUsedForApproval) {
    evidence
      .filter((e) => !e.draft)
      .forEach((e) => {
        if (e.evidenceJSON) {
          const parsedEvidence = JSON.parse(e.evidenceJSON);
          if (
            parsedEvidence.programInfo.progressCheckCompetenceID === compUsedForApproval.ID &&
            compUsedForApproval["Evidence Definitions"][0]
          ) {
            const evidenceDef = compUsedForApproval["Evidence Definitions"][0];
            const part = evidenceDef["Evidence parts"]?.find((part) => part["Progress Check Submission Trigger"]);
            if (part) {
              if (parsedEvidence[part?.Name] === part["Progress Check Submission Trigger"]) {
                result = true;
              }
            }
          }
        }
      });
  }

  return result;
}

/**
 * @param progressCheckCompetenceID
 * @param evidenceDefinitionID
 * @returns true if the field is locked in the Evidence Modal to prevent user input (ConfidenceLevelGraph)
 */
export function isFieldLocked(progressCheckCompetenceID: string | undefined, evidenceDefinitionID: string): boolean {
  if (!progressCheckCompetenceID) {
    return false;
  }

  const competenceIncluded = ["LNA36J9UUQHQ4", "LNA36J9UUQHQ6", "LNA37J9UUQHQ1"].includes(progressCheckCompetenceID);
  const partCanBeLocked = ["LPMLMVO96BJBW", "LPMLTECLVT6U5"].includes(evidenceDefinitionID);

  return competenceIncluded && partCanBeLocked;
}

export function autoCompleteProgressCheckDependsOnTrigger(programID: string) {
  return ["LNA4FTCKB003M"].includes(programID);
}

export function includeSubtitleInProgressCheckEvidenceButton(programID: string): boolean {
  return ["LNA4FTCKB003M"].includes(programID);
}

/**
 * @param submissionState
 * @returns true if the submission state is approved
 */
export function isProgressCheckApproved(submissionState: ProgressCheckSubmission | undefined): boolean {
  return submissionState?.status === SubmissionState.Approved;
}

/**
 * @param submissionState
 * @param allEvidenceAdded
 * @returns true if the submission state is submitted or approved. Otherwise returns whether all the required evidence is not added
 */
export function isProgressCheckButtonDisabled(
  submissionState: ProgressCheckSubmission | undefined,
  allEvidenceAdded: boolean
): boolean {
  if (submissionState) {
    if (submissionState.status === SubmissionState.Submitted || submissionState.status === SubmissionState.Approved) {
      return true;
    }
  }

  return !allEvidenceAdded;
}

export function isWellbeingActivity(competenceID: string = "") {
  return ["LWKJR9KHM5VOO"].includes(competenceID);
}

/**
 * Gets the evidence for generating the Preceptor confidence level graph and maps it to the ConfidenceLevelData object for the graph to use
 * @param confidenceLevelEvidence - The filtered evidence for the confidence level competences
 * @returns The mapped confidence level data if the first required evidence has been added, or null if it has not been added
 */
export function mapConfidenceLevelEvidence(confidenceLevelEvidence: IEvidence[]): ConfidenceLevelData | null {
  const prePreceptorEvidenceID = "LNA36J9UUQHQ0";

  const prePreceptorEvidence = confidenceLevelEvidence.find((evidence: IEvidence) => {
    const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
    const progressCheckCompetenceID = evidenceJSON?.programInfo?.progressCheckCompetenceID || "";

    const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

    return progressCheckCompetenceID === prePreceptorEvidenceID && onHoldReset !== 1 && evidence.draft !== true;
  });

  if (prePreceptorEvidence) {
    const prePreceptorEvidenceJSON = prePreceptorEvidence.evidenceJSON && JSON.parse(prePreceptorEvidence.evidenceJSON);

    let area1Data = [0, 0, 0, 0];
    let area2Data = [0, 0, 0, 0];
    let generalData = [0, 0, 0, 0];

    for (let i = 0; i < confidenceLevelEvidence.length; i++) {
      const evidence = confidenceLevelEvidence[i];
      const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);

      const onHoldReset = evidenceJSON.OnHoldReset ?? 0;

      if (onHoldReset === 1 || evidence.draft === true) {
        continue;
      }

      const index = getConfidenceLevelProgressCheckIndex(evidenceJSON.programInfo?.progressCheckID);

      if (index > -1) {
        if (evidenceJSON.programInfo?.progressCheckCompetenceID === "LNA36J9UUQHQ1") {
          generalData[index] = parseInt(evidenceJSON["Confidence level 1"]);
        } else if (evidenceJSON.programInfo?.progressCheckCompetenceID === "LNA37J9UUQHQ1") {
          area1Data[index] = parseInt(evidenceJSON["End of Preceptorship confidence levels 1"]);
          area2Data[index] = parseInt(evidenceJSON["End of Preceptorship confidence levels 2"]);
        } else {
          area1Data[index] = parseInt(evidenceJSON["Confidence level 1"]);
          area2Data[index] = parseInt(evidenceJSON["Confidence level 2"]);
        }
      }
    }

    return {
      area1: {
        areaName: prePreceptorEvidenceJSON["Area of practice 1 - pre-preceptorship"],
        data: area1Data,
      },
      area2: {
        areaName: prePreceptorEvidenceJSON["Area of practice 2 - pre-preceptorship"],
        data: area2Data,
      },
      general: {
        areaName: "General",
        data: generalData,
      },
    };
  }

  return null;
}

export function mapProgramDataVersionInfo(data: ProgramData[]): ProgramDataVersionInfo[] {
  let object = _.cloneDeep(data);

  return object.map((program) => ({
    Name: program.Name,
    ID: program.ID,
    version: program.version,
  }));
}

/**
 * Tries to find the user's ESR number from the subscription that corresponds to the Program Module passed in
 * @param program - The current Program Module
 * @param subscriptions - All of the user's Salesforce subscriptions
 * @returns The ESR number if it exists, otherwise undefined
 */
export function mapESRNumber(program: ProgramData, subscriptions: IClassSubscription[]): string | undefined {
  if (program && subscriptions) {
    const programSubscriptionIDs = program?.Subscription ?? [];

    const programSubscription: IClassSubscription | undefined = subscriptions?.find(
      (item: IClassSubscription | any) => {
        const subscriptionPrograms: string[] = item.ParaFolioPrograms;

        return (
          subscriptionPrograms?.some((_sub) => program?.Subscription?.includes(_sub)) ||
          programSubscriptionIDs.includes(`${item.SubscriptionID}`)
        );
      }
    );

    return programSubscription?.ESR ?? undefined;
  }

  return undefined;
}

/**
 * @param mentors
 * @returns A mapped string array of the mentors names
 */
export function mapPreceptorNames(mentors: Mentor[] | undefined): string[] | undefined {
  if (mentors) {
    return mentors.map((item) => item.name);
  }

  return undefined;
}

/**
 * @param regions
 * @returns A formatted string of the user's regions
 */
export function mapRegionNames(regions: string[] | undefined): string | undefined {
  if (regions) {
    return regions.join(", ");
  }

  return undefined;
}

/**
 * Checks whether the user has completed their Preceptorship to show congratulations card
 * @returns true if the Preceptorship is complete
 */
export function preceptorshipComplete(
  progressChecks: ProgressCheck[] | undefined,
  allProgressCheckData: AllProgressCheckStatuses[] | undefined | null,
  allEvidence: IEvidence[],
  programID: string | undefined
): boolean {
  if (!progressChecks || !allProgressCheckData || !programID) {
    return false;
  }

  const dataForProgram: AllProgressCheckStatuses | undefined = allProgressCheckData.find(
    (item: any): item is AllProgressCheckStatuses => item.programID === programID
  );

  for (let i = 0; i < progressChecks.length; i++) {
    const check = progressChecks[i];
    const progressCheckStatus = dataForProgram?.pCs.find((item) => item.pCId === check.ID);

    if (progressCheckStatus?.submissions.status === SubmissionState.Approved) {
      continue;
    }

    const progressCheckEvidence = getEvidenceForProgressCheck(allEvidence, check.ID);

    if (progressCheckEvidence?.length > 0) {
      const completed = isAllEvidenceAddedForProgressCheck(progressCheckEvidence, check["Competence/Activity"]);

      if (completed) {
        if (check.Approval === ProgressCheckApproval.NotRequired) {
          continue;
        } else if (progressCheckStatus?.submissions.status === SubmissionState.Submitted) {
          return false;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  return true;
}

/**
 * Removes the progress check that the user has already added all required evidence for
 * @param progressChecks - The progress checks for the program module
 * @param competences - The progress check competences
 * @param program - The current program
 * @param programProgressCheckData - The array of progress check data for all programs
 * @returns The filtered progress check array
 */
export function removeProgressChecks(
  progressChecks: ProgressCheck[],
  allEvidence: IEvidence[],
  program: ProgramData | null,
  programProgressCheckData: AllProgressCheckStatuses[] | null
): ProgressCheck[] {
  let result: ProgressCheck[] = [];

  const currentProgressCheckData =
    programProgressCheckData?.find((item: any): item is AllProgressCheckStatuses => item.programID === program?.ID) ??
    null;

  if (isMTS(program?.ID!)) {
    result = progressChecks?.filter((progressCheck: ProgressCheck) => {
      const evidenceForProgressCheck =
        allEvidence?.filter((evidence: IEvidence) => {
          const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
          const id = evidenceJSON?.programInfo?.progressCheckID || "";

          const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

          return progressCheck.ID === id && onHoldReset !== 1 && evidence.draft !== true;
        }) ?? [];

      return !isProgressCheckApprovedBasedOnCompTrigger(evidenceForProgressCheck, progressCheck["Competence/Activity"]);
    });
  } else {
    // Remove progress checks with all evidence added
    result = progressChecks?.filter((progressCheck: ProgressCheck) => {
      const progressCheckCompetenceIDs = progressCheck["Competence/Activity"].map((item) => item.ID).sort();

      const evidenceForProgressCheck =
        allEvidence?.filter((evidence: IEvidence) => {
          const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
          const id = evidenceJSON?.programInfo?.progressCheckID || "";

          const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

          return progressCheck.ID === id && onHoldReset !== 1 && evidence.draft !== true;
        }) ?? [];

      const competenceIDs = _.uniq(
        evidenceForProgressCheck
          .map((evidence: IEvidence) => {
            const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
            return evidenceJSON?.programInfo?.progressCheckCompetenceID;
          })
          .sort()
      );

      return !_.isEqual(progressCheckCompetenceIDs, competenceIDs);
    });
  }

  // Remove locked progress checks - Either completed or approved
  result = result?.filter((progressCheck: ProgressCheck) => {
    const locked = checkIfProgressCheckIsLocked(progressCheck, progressChecks, allEvidence, currentProgressCheckData);

    return !locked;
  });

  return result;
}

/**
 * Removes the progress check competences that the user has already added evidence for
 * @param competences - The progress check competences
 * @param progressCheckID - The ID of the current progress check
 * @param allEvidence - All of the user's evidence
 * @returns The filtered competence array
 */
export function removeProgressCheckCompetences(
  competences: CompetenceActivityClass[],
  progressCheckID: string,
  allEvidence: IEvidence[],
  programId: string,
  selectedProgressCheckCompetence: CompetenceActivityClass | null
): CompetenceActivityClass[] {
  let result: CompetenceActivityClass[] = [];

  const evidenceForProgressCheck = allEvidence?.filter((evidence: IEvidence) => {
    const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
    const id = evidenceJSON?.programInfo?.progressCheckID ?? "";

    const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

    return progressCheckID === id && onHoldReset !== 1 && evidence.draft !== true;
  });

  const mappedCompetenceCheckIDs = evidenceForProgressCheck?.map((evidence: IEvidence) => {
    const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
    const id = evidenceJSON?.programInfo?.progressCheckCompetenceID || "";

    return id;
  });

  const reviewShouldBeUnlocked = competences
    .filter((comp) => !comp.isFinalReview)
    .filter((comp) => comp["Number Evidences required"])
    .every((comp) => {
      return EvidenceUtils.checkEvidenceAddedForProgressCheckCompetence(evidenceForProgressCheck, comp, programId);
    });

  result = competences?.filter((competence: CompetenceActivityClass) => {
    if (isMTS(programId)) {
      if (selectedProgressCheckCompetence && selectedProgressCheckCompetence.ID === competence.ID) return true;
      if (competence.isFinalReview && !reviewShouldBeUnlocked) return false;

      const multipleAllowed = competence.MultipleAllowed;
      const requiredNumber = competence["Number Evidences required"] ?? 0;
      const evidenceForComp = evidenceForProgressCheck.filter((e) => {
        const evidenceJSON = e.evidenceJSON && JSON.parse(e.evidenceJSON);
        const id = evidenceJSON?.programInfo?.progressCheckCompetenceID || "";
        return id === competence.ID;
      });
      let includeComp = false;
      if (multipleAllowed) {
        includeComp = true;
      } else {
        if (requiredNumber === 0) {
          includeComp = evidenceForComp.length < 1;
        } else {
          includeComp = evidenceForComp.length < requiredNumber;
        }
      }

      return includeComp;
    } else {
      return !mappedCompetenceCheckIDs?.includes(competence.ID);
    }
  });

  result = result.filter((competence: CompetenceActivityClass) => !isWellbeingActivity(competence.ID));

  return result;
}

/**
 * Checks whether user details saved are for the NWAS IC program module.
 * @param programID - The current program ID
 * @returns
 */
export function saveUserDetailsForNWASICProgram(programID: string): boolean {
  return ["LR0HYZYWY6JQK"].includes(programID);
}

/**
 * Checks if the usage data should be calculated based on a competency and matching evidence
 * @param programID - The current program ID
 * @returns
 */
export function shouldCalculateUsageData(programID: string): boolean {
  return ["LR0HYZYWY6JQK"].includes(programID);
}

/**
 * Checks whether the read evidence view should get the progress check information
 * @param programID - The current program ID
 * @returns
 */
export function shouldGetProgressCheckInfo(programID: string): boolean {
  return ["LNA4FTCKB012M", "LNA4FTCKB005M", "LNA4FTCKB003M"].includes(programID);
}
/**
 * Checks whether the input date for supporting evidence should be mandatory.
 * @param programID - The current program ID
 * @returns
 */
export function shouldInputDateOnSupportingEvidenceBeMandatory(programID: string): boolean {
  return ["LR0HYZYWY6JQK"].includes(programID);
}

/**
 * Checks whether the "BETA" label should be shown on the DetailsContentCollapsible component.
 * @param programID - The current program ID
 * @returns
 */
export function showBetaLabelForDetailsCollapsible(programID: string): boolean {
  return ["LR0HYZYWY6JQK", "LNA4FTCKB012M", "LNA4FTCKB005M"].includes(programID);
}

/**
 * Checks whether the "BETA" label should be shown on the program card.
 * @param programID - The current program ID
 * @returns
 */
export function showBetaLabelForProgramCard(programID: string): boolean {
  return ["LR0HYZYWY6JQK", "LNA4FTCKB012M", "LNA4FTCKB005M"].includes(programID);
}

/**
 * Checks whether the certificate inputs should be shown after (or before) role selection.
 * @param programID - The current program ID
 * @returns
 */
export function showCertsAfterRoleSelection(programID: string): boolean {
  return ["LR0HYZYWY6JQK"].includes(programID);
}

export function showConfidenceLevelGraph(programID: string): boolean {
  return ["LNA4FTCKB012M"].includes(programID);
}

/**
 * Checks whether the certificate description should be shown under the certificate input
 * @param programID - The current program ID
 * @returns
 */
export function showDescriptionForCertificate(programID: string): boolean {
  return ["LR0HYZYWY6JQK"].includes(programID);
}

/**
 * Checks whether the evidence block should be displayed
 * @param programID - The current program ID
 * @param user - The user object
 * @returns true if the Program Module should have the evidence list displayed in the Program Home page
 */
export function showEvidenceBlock(programID: string, user: any): boolean {
  if (user) {
    if (isEPRR(programID) && !isEprrDetailsSet(user)) return false;
  }
  return ["LNLT7JSYDT5PJ", "LNA4FTCKB012M", "LR0HYZYWY6JQK", "LNA4FTCKB005M"].includes(programID);
}

/**
 * Checks whether the evidence count should be displayed
 * @param programID - The current program ID
 * @returns
 */
export function showEvidenceCount(programID: string): boolean {
  return ["LNLT7JSYDT5PJ"].includes(programID);
}

/**
 * Checks whether optional standards should be shown for a program in the program home page.
 * @param programID - The current program ID
 * @returns
 */
export function showMandatoryStandardsInProgramHomePage(programID: string): boolean {
  return ["LR0HYZYWY6JQK"].includes(programID);
}

/**
 * Checks whether optional standards should be shown for a program in the program details page.
 * @param programID - The current program ID
 * @returns
 */
export function showOptionalStandardsInDetailsPage(programID: string): boolean {
  return ["LR0HYZYWY6JQK"].includes(programID);
}

/**
 * Checks whether the details card should be displayed
 * @param programID - The current program ID
 * @returns
 */
export function showPreceptorshipDetails(programID: string): boolean {
  return ["LNA4FTCKB012M", "LNA4FTCKB005M"].includes(programID);
}

/**
 * Checks whether areas should be shown for a program.
 * @param programID - The current program ID
 * @returns
 */
export function showProgramAreas(programID: string): boolean {
  return ["LR0HYZYWY6JQK"].includes(programID);
}

/**
 * Checks whether the user's program certificates should be displayed
 * @param programID - The current program ID
 * @returns
 */
export function showProgramCertificates(programID: string): boolean {
  return ["LNLT7JSYDT5PJ"].includes(programID);
}

/**
 * Checks whether a trust label should be shown on the DetailsContentCollapsible component.
 * @param programID - The current program ID
 * @returns
 */
export function showTrustLabelForDetailsCollapsible(programID: string): boolean {
  return ["LR0HYZYWY6JQK", "LNA4FTCKB003M"].includes(programID);
}

/**
 * Checks whether the progress for the Progress Checks should be displayed on the Dashboard Card
 * @param programID - The current program ID
 * @returns
 */
export function showProgressCheckProgress(programID: string): boolean {
  return ["LNA4FTCKB012M", "LNA4FTCKB005M", "LNA4FTCKB003M"].includes(programID);
}

export function showProgressCheckProgressOnDashboard(programID: string): boolean {
  return ["LNA4FTCKB012M", "LNA4FTCKB005M"].includes(programID);
}

/**
 * Checks whether the standards label should be shown on the ProgramEvidenceCard component (e.g. for NWAS IC).
 * @param programID - The current program ID
 * @returns
 */
export function showStandardsLabelForEvidenceCard(programID: string): boolean {
  return ["LR0HYZYWY6JQK"].includes(programID);
}

/**
 * Checks whether the pill above the header in the program info card should be displayed
 * @param programID - The current program ID
 * @returns
 */
export function showTopPill(programID: string): boolean {
  return ["LR0HYZYWY6JQK"].includes(programID);
}

/**
 * Checks whether the Program Home page should display the old Details card (SWAST HART EPRR) or the new Details card (NWAS EPRR)
 * @param programID
 * @returns
 */
export function useDetailsCardInsteadOfProgramInfoCard(programID: string) {
  return ["LR0HYZYWY6JQK", "LNA4FTCKB003M"].includes(programID);
}

/**
 * Checks whether the details component should use radio buttons instead of checkboxes for selecting roles.
 * @param programID - The current program ID
 * @returns
 */
export function useRadioRoleSelector(programID: string): boolean {
  return ["LR0HYZYWY6JQK"].includes(programID);
}

/**
 * Checks whether the certificate input components should use names as the labels.
 * @param programID - The current program ID
 * @returns
 */
export function useNameAsCertLabel(programID: string): boolean {
  return ["LR0HYZYWY6JQK"].includes(programID);
}

export function showProgressCheckEvidenceCount(progressCheckID: string): boolean {
  return ["LNA4GMRKB061M", "LNA4GMRKB071M"].includes(progressCheckID);
}

export function getLearningOutcomesAdded(progressCheckEvidence: IEvidence[], compId?: string): string[] {
  if (!compId) return [];

  const learningOutcomeIds: string[] = [];

  for (const evidence of progressCheckEvidence.filter((e) => !e.draft)) {
    const parsedEvidence = JSON.parse(evidence.evidenceJSON);
    if (parsedEvidence["Comp Selector"]) {
      if (parsedEvidence["Comp Selector"].length && !parsedEvidence.OnHoldReset) {
        const compFound = parsedEvidence["Comp Selector"].find((item: any) => item.compId === compId);
        if (compFound) {
          compFound.selectedOutcomes.forEach((outcome: any) => learningOutcomeIds.push(outcome.id));
        }
      }
    } else {
      const outcomes = parsedEvidence.LearningOutcomes;
      if (outcomes && outcomes.length && !parsedEvidence.OnHoldReset) {
        outcomes.forEach((outcome: any) => learningOutcomeIds.push(outcome.ID));
      }
    }
  }

  return learningOutcomeIds;
}

export function getMtsRole(user: IUser, programID: string): string {
  const role = user.programRoles?.find((role) => role.programID === programID);
  if (role && role.programRoles.length) {
    return role.programRoles[0];
  }

  return "unset";
}

export function getMtsTeam(user: IUser, programID: string): string {
  const role = user.programRoles?.find((role) => role.programID === programID);
  if (role && role?.team) {
    return role.team;
  }

  return "unset";
}

/**
 * Before NWAS IC was introduced, programRoles in the IUserProgramRoles interface was just a string[].
 * Now it is a more descriptive array of objects, so this function checks if a particular program is
 * using the old format, or the new format.
 * @param programID - The current program ID
 * @returns
 */
export function usingDescriptiveProgramRolesFormat(programID: string): boolean {
  return ["LR0HYZYWY6JQK"].includes(programID);
}

/**
 * Checks whether the program is LAS MTS
 * @param programID - The current program ID
 * @returns
 */
export function isMTS(programID: string): boolean {
  return ["LNA4FTCKB003M"].includes(programID);
}

/**
 * Checks the enabling the confirm button requires special/different validation. Specifically used by the NWAS IC program module.
 * @param programID - The current program ID
 * @returns
 */
export function validateNWASICDetails(programID: string): boolean {
  return ["LR0HYZYWY6JQK", "LNA4FTCKB003M"].includes(programID);
}
