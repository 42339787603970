import { IonButton, IonImg, IonSpinner } from "@ionic/react";
import DetailsContentCollapsible from "../../components/programs/DetailsContentCollapsible";
import { useHistory, useLocation, useParams } from "react-router";
import { useProgramData } from "../../hooks/data/useProgramData";
import { useRecoilStateLoadable } from "recoil";
import { IExportType, IUser } from "../../Interfaces";
import { userAtom } from "../../state/State";
import * as ProgramUtils from "../../utils/programUtils";
import { Icon_Export_Blue, Icon_Pencil } from "../../assets/images";
import { IMtsDetailsProps } from "../../types/Components";

function MtsDetails(props: IMtsDetailsProps) {
  const history = useHistory();
  const params = useParams<{ programId: string }>();

  const { userPrograms } = useProgramData();

  const program = userPrograms.find((p) => p.ID === params.programId)!;

  const [user, setUser] = useRecoilStateLoadable<IUser | null>(userAtom);

  return (
    <DetailsContentCollapsible title={user && user.state === "hasValue" ? user.contents?.name! : ""} program={program}>
      <div className="text-acc-17px leading-[1.54] tracking-[-0.1px] text-grey-70">
        {user && user.state === "hasValue" ? user.contents?.email! : ""}
      </div>
      <div className="mt-[8px] mb-[4px]">
        <div className="text-acc-13px leading-[1.54] tracking-[-0.1px] text-grey-70">Your ESR</div>
        <div className="text-acc-17px leading-[1.53] tracking-[-0.3px] text-grey-90">
          {ProgramUtils.mapESRNumber(program, user.contents?.subscriptions)}
        </div>
      </div>
      <div className="mt-[8px] mb-[4px]">
        <div className="text-acc-13px leading-[1.54] tracking-[-0.1px] text-grey-70">Your role</div>
        <div className="text-acc-17px leading-[1.53] tracking-[-0.3px] text-grey-90">
          {ProgramUtils.getMtsRole(user.contents, program.ID)}
        </div>
      </div>
      <div className="mt-[8px] mb-[4px]">
        <div className="text-acc-13px leading-[1.54] tracking-[-0.1px] text-grey-70">Your team</div>
        <div className="text-acc-17px leading-[1.53] tracking-[-0.3px] text-grey-90">
          {ProgramUtils.getMtsTeam(user.contents, program.ID)}
        </div>
      </div>
      <div className="mt-3 flex gap-2">
        <IonButton
          className="flex-1 program-card-button h-[50px]"
          onClick={() => props.exportPressed(IExportType.PROGRAM, program)}
        >
          {props.exportingEvidence && props.currentExportType === IExportType.PROGRAM ? (
            <IonSpinner className="text-cta-blue" />
          ) : (
            <>
              <IonImg className="w-[40px] h-[40px]" src={Icon_Export_Blue} />
              <div className="text-[16px] font-semibold text-cta-blue">Export</div>
            </>
          )}
        </IonButton>
        <IonButton
          className="flex-1 program-card-button h-[50px]"
          onClick={() => history.push(`/dashboard/program/${program.ID}/mts-details`)}
        >
          <IonImg className="w-[24px] h-[24px] mr-1" src={Icon_Pencil} />
          <div className="text-[16px] font-semibold text-cta-blue">Edit</div>
        </IonButton>
      </div>
    </DetailsContentCollapsible>
  );
}

export default MtsDetails;
