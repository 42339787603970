import { useRecoilStateLoadable, useRecoilValueLoadable } from "recoil";
import { IEvidence, IMandatoryStandard, IOptionalStandard, IUser, ProgramData, ProgressCheck } from "../../Interfaces";
import { evidenceAtom, userAtom } from "../../state/State";
import { useProgramData } from "../../hooks/data/useProgramData";
import { subYears } from "date-fns";
import SegmentedProgressBar from "../common/SegmentedProgressBar";
import { useEffect, useState } from "react";
import * as ProgramUtils from "../../utils/programUtils";
import { Icon_PCs_Approved } from "../../assets/images";

const MtsOverview: React.FC<{}> = ({}) => {
  const [user, _] = useRecoilStateLoadable<IUser | null>(userAtom);
  const evidence = useRecoilValueLoadable<IEvidence[] | null>(evidenceAtom);

  const { userPrograms } = useProgramData();

  const program: ProgramData = userPrograms.find((item) => item.ID === "LNA4FTCKB003M")!;

  const [progressChecks, setProgressChecks] = useState<ProgressCheck[]>([]);

  let programEvidence: IEvidence[] = [];
  if (evidence) {
    programEvidence = evidence.contents?.filter((evidence: IEvidence) => {
      const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
      const programName = evidenceJSON?.programInfo?.program || "";

      const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

      return (
        programName === program.Name &&
        onHoldReset !== 1 &&
        evidence.draft !== true &&
        new Date(evidence.date) >= subYears(new Date(), 2)
      );
    });
  }

  useEffect(() => {
    const getProgressChecks = (): void => {
      if (program) {
        const _progressChecks = program.ProgressChecks || [];

        if (_progressChecks) {
          setProgressChecks(_progressChecks);
        }
      }
    };

    getProgressChecks();
  }, [program]);

  // TODO this is also used in CustomProgressCheckButton - consider putting in ProgramUtils.ts instead. comps count is also done in CustomProgressCheckEvidenceButton.tsx
  function calculateCompsDone(programEvidence: IEvidence[], progressCheck: ProgressCheck) {
    if (!programEvidence) {
      console.log("programEvidence is undefined");
      return 0;
    }
    const filteredEvidence = programEvidence.filter((e) => {
      const parsed = JSON.parse(e.evidenceJSON);
      const onHoldReset = parsed.OnHoldReset ?? 0;
      if (!e.draft && onHoldReset === 0 && progressCheck.ID === parsed.programInfo.progressCheckID) {
        return true;
      }
    });

    let count = 0;
    progressCheck["Competence/Activity"].forEach((comp) => {
      if (comp["Number Evidences required"] === 0) return;

      const requiredNumber = comp["Number Evidences required"] ?? 0;
      const evidenceForComp = filteredEvidence.filter((e) => {
        const parsed = JSON.parse(e.evidenceJSON);
        return parsed.programInfo.progressCheckCompetenceID == comp.ID;
      });
      if (evidenceForComp.length) {
        if (comp.MultipleAllowed) {
          if (evidenceForComp.length > 0) count += 1;
        } else {
          if (evidenceForComp.length >= requiredNumber) count += 1;
        }
      }
    });

    return count;
  }

  const allApproved = progressChecks.every((pc) => {
    return ProgramUtils.isProgressCheckApprovedBasedOnCompTrigger(programEvidence, pc["Competence/Activity"]);
  });

  return (
    <div>
      {allApproved ? (
        <div>
          <div className="text-acc-13px">You’ve completed your Manchester Triage System. Well done!</div>
          <img className="ml-auto mr-auto mt-4 mb-1" width={250} height={20} src={Icon_PCs_Approved} alt="" />
        </div>
      ) : (
        <>
          {progressChecks.map((pc) => {
            const num = calculateCompsDone(programEvidence, pc);
            const den = pc["Competence/Activity"].filter((comp) => comp["Number Evidences required"] != 0).length;
            return (
              <SegmentedProgressBar
                key={pc.ID}
                title={`Mandatory ${pc.Name} completed`}
                items={pc["Competence/Activity"].filter((comp) => comp["Number Evidences required"] != 0)}
                numerator={num}
                denominator={den}
              />
            );
          })}
        </>
      )}
    </div>
  );
};

export default MtsOverview;
