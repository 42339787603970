import { IonImg, IonItem } from "@ionic/react";
import { PropsWithChildren } from "react";
import { ICustomProgressCheckButton } from "../../types/Components";
import { Button_Disclosure_Right_Grey } from "../../assets/images";

const CustomProgressCheckButton = (props: PropsWithChildren<ICustomProgressCheckButton>) => {
  function getProgressStatusBackgroundColor(status: string) {
    if (status === "Not started" || status === "Locked") {
      return "#dee2e6";
    }

    if (status === "Reset") {
      return "#FEEBC8";
    }

    if (status === "In progress") {
      return "#feebc8";
    }

    if (status === "Completed") {
      return "#C6F7C1";
    }

    if (status === "Attention") {
      return "#ed242b";
    }

    if (status === "Open") {
      return "#C8ECFE";
    }

    return "#C6F7C1";
  }

  function getProgressStatusTextColor(status: string) {
    if (status === "Not started" || status === "Locked") {
      return "#868e96";
    }

    if (status === "Reset") {
      return "#92472a";
    }

    if (status === "In progress") {
      return "#9C4221";
    }

    if (status === "Completed") {
      return "#085333";
    }

    if (status === "Attention") {
      return "#FFFFFF";
    }

    if (status === "Open") {
      return "#007aff";
    }

    return "#9C4221";
  }

  const compsDone = calculateCompsDone();
  const compCountLimit = props.progressCheck["Competence/Activity"].filter(
    (comp) => comp["Number Evidences required"] != 0
  ).length;

  function calculateCompsDone() {
    const filteredEvidence = props.programEvidence.filter((e) => {
      const parsed = JSON.parse(e.evidenceJSON);
      const onHoldReset = parsed.OnHoldReset ?? 0;
      if (!e.draft && onHoldReset === 0 && props.progressCheck.ID === parsed.programInfo.progressCheckID) {
        return true;
      }
    });

    let count = 0;
    props.progressCheck["Competence/Activity"].forEach((comp) => {
      if (comp["Number Evidences required"] === 0) return;

      const requiredNumber = comp["Number Evidences required"] ?? 0;
      const evidenceForComp = filteredEvidence.filter((e) => {
        const parsed = JSON.parse(e.evidenceJSON);
        return parsed.programInfo.progressCheckCompetenceID == comp.ID;
      });
      if (evidenceForComp.length) {
        if (comp.MultipleAllowed) {
          if (evidenceForComp.length > 0) count += 1;
        } else {
          if (evidenceForComp.length >= requiredNumber) count += 1;
        }
      }
    });

    return count;
  }

  return (
    <IonItem
      button
      detail={false}
      onClick={props.handleClick}
      className="custom-progress-check-button flex flex-row !justify-between items-center"
    >
      <div className="min-h-[50px] w-full flex flex-row !justify-between items-center py-[8px]">
        <div className="flex flex-col flex-[-1]">
          <div className="text-[15px] leading-[1.33] tracking-[-0.3px] text-grey-90">{props.title}</div>
          <div className="text-[11px] leading-[1.273] text-grey-60">{`${compsDone}/${compCountLimit} mandatory ${props.title.toLowerCase()}`}</div>
        </div>
        {props.progressLabel.label && (
          <div className="flex flex-row items-center ml-[8px] flex-shrink-0">
            <div
              className={`flex shrink-0 items-center px-[8px] py-[2.25] justify-center h-20px rounded-[10px]`}
              style={{ backgroundColor: getProgressStatusBackgroundColor(props.progressLabel.status) }}
            >
              <div
                className={"h-[20px] leading-[20px] text-[13px] my-[2.25] font-semibold"}
                style={{ color: getProgressStatusTextColor(props.progressLabel.status) }}
              >
                {props.progressLabel.label}
              </div>
            </div>
            <IonImg
              className="w-24px h-24px min-w-[24px] min-h-[24px] mr-[-5px] flex flex-[-1]"
              src={Button_Disclosure_Right_Grey}
            />
          </div>
        )}
      </div>
    </IonItem>
  );
};

export default CustomProgressCheckButton;
