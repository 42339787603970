import { Capacitor } from "@capacitor/core";
import { IonButton, IonButtons, IonContent, IonHeader, IonImg, IonModal, IonToolbar } from "@ionic/react";
import DOMPurify from "dompurify";
import { useRecoilValue } from "recoil";
import { Button_Audio_Play_White, Button_Audio_Stop } from "../../assets/images";
import DataController from "../../controllers/DataController";
import { useTextToSpeech } from "../../hooks/accessibility/useTextToSpeech";
import { IAccessibilitySettings, IInfoModal } from "../../Interfaces";
import { accessibilityAtom } from "../../state/State";
import ContentContainer from "../common/ContentContainer";
import HeaderTitle from "../common/HeaderTitle";

const GetStartedModal = ({ visible, closeModal, body, program }: IInfoModal) => {
  const accessibilitySettings = useRecoilValue<IAccessibilitySettings | null>(accessibilityAtom);

  const { handleTTS, playing, stopTTS } = useTextToSpeech("infoModalContainer");

  const sanitizeData = (data: string) => ({
    __html: DOMPurify.sanitize(data, {
      ADD_TAGS: ["iframe"],
      ALLOWED_ATTR: [
        "class",
        "width",
        "height",
        "src",
        "title",
        "target",
        "rel",
        "href",
        "title",
        "allow",
        "referrerPolicy",
        "allowFullScreen",
      ],
    }),
  });

  return (
    <IonModal
      id="summaryOfWorkModal"
      isOpen={visible}
      onIonModalWillDismiss={() => {
        stopTTS();
        closeModal();
      }}
      backdropDismiss={true}
      className="fullscreen"
    >
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonButton
              className="cancelButton"
              mode="ios"
              onClick={() => {
                stopTTS();
                closeModal();
              }}
            >
              {"Close"}
            </IonButton>
          </IonButtons>
          <HeaderTitle>{"Get Started"}</HeaderTitle>
          {accessibilitySettings?.textToSpeech && (
            <IonButtons slot="end">
              <IonButton className="header-button" mode="ios" onClick={() => handleTTS()}>
                <IonImg src={playing ? Button_Audio_Stop : Button_Audio_Play_White} className="headerIcon" />
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent className="page-background whiteBackground">
        <ContentContainer>
          <div className="infoModalContainer" id="infoModalContainer">
            {body && <div className="tipModalText mx-0 mt-0" dangerouslySetInnerHTML={sanitizeData(body)} />}
          </div>
        </ContentContainer>
      </IonContent>
    </IonModal>
  );
};

export default GetStartedModal;
